<template>
    <v-card color="transparent" flat class="pb-7 mt-5">
        <v-row no-gutters>
            <v-col
                v-if="content && content.text && content.text.length > 3"
                cols="12"
                md="6"
                class="d-flex align-center"
            >
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-title class="text-h5">Club Infos</v-card-title>
                    </v-col>
                    <v-col cols="12">
                        <v-card-text
                            style="max-width: 400px"
                            v-if="content && content.text"
                            v-html="content.text"
                            class="font-weight-thin pt-0"
                        ></v-card-text>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="mx-auto" cols="12" md="6">
                <v-carousel
                    v-if="content.pictures && content.pictures.length > 0"
                    class="rounded-lg mt-3 mx-auto"
                    :show-arrows="false"
                    delimiter-icon="mdi-square"
                    hide-delimiter-background
                    cycle
                    height="auto"
                >
                    <v-carousel-item
                        :src="getDynamicPictureSrc(pic)"
                        :alt="pic.alt"
                        aspect-ratio="16/9"
                        v-for="pic in content.pictures"
                        :key="pic.src"
                    ></v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                default: null,
                required: true,
            },
        },
        data() {
            return {
                url: 'https://app.wellcomefit.ch',
            }
        },
        methods: {
            getPictureSrc(pic, size) {
                return `${pic.src.replace('.webp', '')}-${size}.webp`
            },
            getDynamicPictureSrc(pic) {
                const width = window.innerWidth
                if (width >= 1024) {
                    return this.url + '/api/website/image/' + pic.src
                } else if (width >= 640) {
                    return this.url + '/api/website/image/' + this.getPictureSrc(pic, 'large')
                } else {
                    return this.url + '/api/website/image/' + this.getPictureSrc(pic, 'medium')
                }
            },
        },
    }
</script>
<style scoped></style>
