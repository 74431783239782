<template>
    <div
        class="header-content d-flex justify-center"
        :style="{ 'margin-top': '64px', 'margin-bottom': dynamicPadding }"
    >
        <div v-if="loading" class="loading-video">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
                class="video-loader mx-auto"
            ></v-progress-circular>
        </div>
        <video
            v-if="video"
            :class="{ loading }"
            :src="getDynamicVideoSrc(video)"
            autoplay
            muted
            loop
            playsinline
            class="header-video"
            @loadedmetadata="onVideoLoaded"
        ></video>
    </div>
</template>
<script>
    import placeholder from '@/assets/videos/placeholder/placeholder.mp4'
    export default {
        props: {
            video: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                placeholder,
                url: 'https://app.wellcomefit.ch',
                loading: true, // Ladezustand des Videos
            }
        },
        computed: {
            dynamicPadding() {
                const { xs, sm, md, lg, xl, width } = this.$vuetify.display

                if (xs) return '5px' // Kleine Geräte
                if (sm) return '5px'
                if (md) return '5px'
                if (lg) return '20px'
                if (xl && width <= 1920) return '20px ' // Standard-xl
                if (width > 1920 && width <= 2560) return '20px ' // Zwischen 1920px und 2560px
                if (width > 2560) return '20px' // Größer als 2560px (z.B. 5K)
                return '20px' // Fallback
            },
        },
        methods: {
            getVideoSrc(vid, size) {
                return `${vid.replace('.mp4', '')}-${size}.mp4`
            },
            getDynamicVideoSrc(vid) {
                const width = window.innerWidth
                if (width >= 1024) {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'original')
                } else if (width >= 640) {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'large')
                } else {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'small')
                }
            },
            onVideoLoaded() {
                this.loading = false // Deaktiviere Ladeanimation, sobald das Video geladen ist
            },
        },
    }
</script>

<style lang="scss" scoped>
    .loading-video {
        position: relative;
        text-align: center;
        color: white;
        border-bottom: 5px solid #00963f;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        aspect-ratio: 16 / 9; // Stellt das Seitenverhältnis auf 16:9 ein
        width: 100%; // Nimmt die gesamte Breite des Containers ein
        @media only screen and (min-width: 1000px) {
            height: 70vh !important;
        }
    }
    .header-content {
        position: relative;
        text-align: center;
        color: white;
        border-bottom: 5px solid #00963f;
        display: flex;
        max-height: 400px;
        @media only screen and (min-width: 1000px) {
            max-height: 70vh !important;
        }
    }

    .header-video {
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: -1px;
    }
    .header-video.loading {
        width: 0% !important;
        height: 0% !important;
    }
</style>
