!
<template>
    <v-row>
        <!-- Job Cards -->
        <v-col v-for="(job, i) in jobs" :key="job._id + '-' + i" cols="12" sm="6">
            <v-card variant="outlined" color="#313131" class="py-2" @click="showDetails(job)">
                <v-card-subtitle class="pb-0 pt-2">{{ job.studio?.name }}</v-card-subtitle>
                <v-card-title style="word-break: auto-phrase !important" class="pt-0 py-1 font-weight-bold">
                    {{ job.type.title }}
                </v-card-title>
                <v-chip v-if="job.durationTitle && job.durationTitle.length > 2" class="mx-5 mb-2">
                    {{ job.durationTitle }}
                </v-chip>
            </v-card>
        </v-col>

        <!-- Job Details Modal -->
        <v-dialog fullscreen persistent v-model="showDialog">
            <v-card style="padding-top: 64px" v-if="selectedJob">
                <v-container>
                    <v-row>
                        <!-- Job Image -->
                        <v-col cols="12" sm="6" class="order-md-last d-flex">
                            <v-img
                                class="mx-auto"
                                height="350"
                                width="350"
                                v-if="selectedJob.type.picture"
                                :src="baseUrl + '/api/website/image/' + selectedJob.type.picture"
                            ></v-img>
                        </v-col>

                        <!-- Job Details -->
                        <v-col cols="12" sm="6">
                            <v-card-title style="word-break: auto-phrase !important" class="font-weight-bold mx-0 px-0">
                                {{ selectedJob.type.title }} {{ selectedJob.durationTitle }}
                            </v-card-title>

                            <!-- Studio Address -->
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card-subtitle style="opacity: 1" class="font-weight-bold mt-4 mx-0 px-0">
                                        Studio
                                    </v-card-subtitle>
                                    {{ selectedJob.studio?.art }} {{ selectedJob.studio?.name }}
                                    <br />
                                    {{ selectedJob.studio?.adresse }}
                                </v-col>
                            </v-row>

                            <!-- Contact Information -->
                            <v-card-subtitle class="font-weight-bold mt-4 mx-0 px-0" style="opacity: 1">
                                Kontakt
                            </v-card-subtitle>
                            <v-row no-gutters>
                                <template v-if="selectedJob.useCustomContact && selectedJob.contactInfo">
                                    <v-col cols="12">
                                        {{ selectedJob.contactInfo.firstName }} {{ selectedJob.contactInfo.lastName }}
                                    </v-col>
                                    <v-col
                                        v-if="selectedJob.contactInfo.email && selectedJob.contactInfo.email.length > 3"
                                        cols="12"
                                    >
                                        <v-btn
                                            variant="text"
                                            :ripple="false"
                                            @click="email(selectedJob.contactInfo.email)"
                                            class="no-uppercase px-0 mx-0 py-0 my-0"
                                            size="large"
                                        >
                                            <v-icon class="mr-2">mdi-email</v-icon>
                                            {{ selectedJob.contactInfo.email }}
                                        </v-btn>
                                    </v-col>
                                    <v-col
                                        v-if="selectedJob.contactInfo.phone && selectedJob.contactInfo.phone.length > 3"
                                        cols="12"
                                    >
                                        <v-btn
                                            variant="text"
                                            :ripple="false"
                                            class="no-uppercase px-0 mx-0 py-0 my-0"
                                            size="large"
                                            @click="call(selectedJob.contactInfo.phone)"
                                        >
                                            <v-icon class="mr-2">mdi-phone</v-icon>
                                            {{ selectedJob.contactInfo.phone }}
                                        </v-btn>
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols="12">
                                        {{ selectedJob.type.contactInfo.firstName }}
                                        {{ selectedJob.type.contactInfo.lastName }}
                                    </v-col>
                                    <v-col
                                        v-if="
                                            selectedJob.type.contactInfo.email &&
                                            selectedJob.type.contactInfo.email.length > 3
                                        "
                                        cols="12"
                                    >
                                        <v-btn
                                            class="no-uppercase px-0 mx-0 py-0 my-0"
                                            size="large"
                                            variant="text"
                                            :ripple="false"
                                            @click="email(selectedJob.type.contactInfo.email)"
                                        >
                                            <v-icon class="mr-2">mdi-email</v-icon>
                                            {{ selectedJob.type.contactInfo.email }}
                                        </v-btn>
                                    </v-col>
                                    <v-col
                                        v-if="
                                            selectedJob.type.contactInfo.phone &&
                                            selectedJob.type.contactInfo.phone.length > 3
                                        "
                                        cols="12"
                                    >
                                        <v-btn
                                            variant="text"
                                            class="no-uppercase px-0 mx-0 py-0 my-0"
                                            size="large"
                                            :ripple="false"
                                            @click="call(selectedJob.type.contactInfo.phone)"
                                        >
                                            <v-icon class="mr-2">mdi-phone</v-icon>
                                            {{ selectedJob.type.contactInfo.phone }}
                                        </v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                            <!-- Apply Button -->
                            <v-row>
                                <v-btn
                                    color="primary"
                                    class="mx-5"
                                    @click="
                                        email(
                                            selectedJob.useCustomContact
                                                ? selectedJob.contactInfo.email
                                                : selectedJob.type.contactInfo.email
                                        )
                                    "
                                >
                                    Jetzt bewerben
                                </v-btn>
                            </v-row>

                            <!-- Job Content -->
                            <v-card-text v-if="selectedJob.type.content" class="mt-4 mx-0 px-0">
                                <p class="ql-editor" v-html="selectedJob.type.content"></p>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-container>

                <!-- Back Button -->
                <v-card-actions class="mx-auto">
                    <v-btn @click="showDialog = false" text>Zurück</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: {
            jobs: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                baseUrl: 'https://app.wellcomefit.ch', // Adjust the base URL to your server
                selectedJob: null,
                showDialog: false,
            }
        },
        methods: {
            call(phone) {
                window.location.href = `tel:${phone}`
            },
            email(email) {
                window.location.href = `mailto:${email}`
            },
            showDetails(job) {
                this.selectedJob = job
                this.showDialog = true
            },
        },
    }
</script>

<style scoped>
    .customFontColor {
        color: #313131 !important;
    }
    ::v-deep .no-uppercase {
        text-transform: unset !important;
        letter-spacing: normal !important;
    }
    .customFontColor {
        color: #313131 !important;
    }

    /* Benutzerdefinierter Stil für Aufzählungen */
    ::v-deep .ql-editor ul {
        list-style-type: none;
        padding-left: 1.5em; /* Platz für das Emoji */
    }

    ::v-deep .ql-editor ul li:before {
        content: '◾'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
        position: absolute;
        margin-left: -1.5em; /* Positionierung des Emojis */
    }

    ::v-deep .ql-editor ol {
        list-style-type: none;
        padding-left: 1.5em; /* Platz für das Emoji */
    }

    ::v-deep .ql-editor ol li:before {
        content: '👉'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
        position: absolute;
        margin-left: -1.5em; /* Positionierung des Emojis */
    }
</style>
