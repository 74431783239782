<template>
    <v-container style="max-width: 700px">
        <h3 class="font-weight-bold mb-5">ULTRA ALL INCLUSIVE</h3>
        <v-expansion-panels multiple>
            <!-- Ohne Gebühr -->
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <v-icon start>mdi-minus-circle-outline</v-icon>
                    Ohne Gebühr
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    Minibar (2x Bier, 2x Cola, 2x Fanta, 2x Wasser und 2x Mineralwasser), 24 Stunden Eiscreme (bekannte
                    Marken wie Algida, Carte D’or), alle Softgetränke, frisch gepresster Orangen- und Grapefruitsaft
                    beim Frühstück, alle lokalen alkoholischen Getränke (Ausnahme Weinsorten), Energy Drink,
                    Sekt/Champagner sowie alle Import-Getränke mit Ausnahme von einigen Premiumgetränken, Türkisches
                    Fladenbrot Service am Strand, Strandtücher, Safe, Türkischer Hammam, Sauna, Wi-Fi (Zimmer, Lobby und
                    Poolbereich), Türkischer Kaffee mit Patisserie, Aquapark und Tennis bar, Business-Ecke, Mini Club,
                    Beach Volleyball, Wasserball, Tischtennis, Shuffleboard, Boccia, Dart, Bogenschiessen
                </v-expansion-panel-text>
            </v-expansion-panel>

            <!-- Gegen Gebühr -->
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <v-icon start>mdi-plus-circle-outline</v-icon>
                    Gegen Gebühr
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    Premiumgetränke, diverse Weinsorten, Tennisplätze und Kurse, Padel-Tennisplätze, Tennisausrüstung,
                    Billard, Arcade-Spiele, Massage, VIP-Hammam, Arzt, Geschäfte, Wäsche Service, Friseur, Fotograf,
                    Kinderwagenverleih, Wassersportmöglichkeiten (Fremdanbieter), Telefon, Panorama A la Carte
                    Restaurant, Teppanyaki A la Carte Restaurant, Pavillonservice am Strand, Mini Golf (mit
                    Vorreservierung) und Kletterpark (mit Vorreservierung)
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
    export default {
        name: 'UltraAllInclusive',
    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold;
    }
</style>
