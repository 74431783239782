<template>
    <v-container>
        <!-- Offers Section -->
        <v-card v-if="filteredOffers.length" variant="flat" class="mb-6">
            <v-card-title
                :class="{ 'text-center': !$vuetify.display.mdAndUp, 'text-left mb-2 mt-4': $vuetify.display.mdAndUp }"
                class="text-h5 font-weight-medium px-0 pb-3"
            >
                Angebot
            </v-card-title>

            <!-- Desktop Ansicht: Grid -->
            <v-row v-if="$vuetify.display.mdAndUp" justify="center" align="stretch" dense>
                <v-col
                    v-for="offer in filteredOffers"
                    :key="offer._id"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    class="d-flex justify-center"
                >
                    <v-card variant="tonal" color="primary" class="pa-3 d-flex flex-row align-center">
                        <v-icon size="large" class="mr-3">{{ offer.icon }}</v-icon>
                        <span class="text-subtitle2 font-weight-bold" style="text-transform: uppercase">
                            {{ offer.title }}
                        </span>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Mobile Ansicht: Carousel mit mehreren Karten pro Slide -->
            <v-carousel class="mb-5" height="400px" v-else :show-arrows="false" hide-delimiter-background>
                <v-carousel-item v-for="(group, index) in groupedOffers" :key="'offer-group-' + index">
                    <v-row justify="center" align="stretch" dense>
                        <v-col v-for="offer in group" :key="offer._id" cols="12" class="d-flex justify-center">
                            <v-card variant="tonal" color="primary" class="pa-3 d-flex flex-row align-center">
                                <v-icon size="large" class="mr-3">{{ offer.icon }}</v-icon>
                                <span class="text-subtitle2 font-weight-bold" style="text-transform: uppercase">
                                    {{ offer.title }}
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-card>

        <!-- Services Section -->
        <v-card v-if="filteredServices.length > 0" variant="flat">
            <v-card-title
                :class="{ 'text-center': !$vuetify.display.mdAndUp, 'text-left mb-2 mt-4': $vuetify.display.mdAndUp }"
                class="text-h5 font-weight-medium px-0 pb-3"
            >
                Leistungen
            </v-card-title>

            <!-- Desktop Ansicht: Grid -->
            <v-row v-if="$vuetify.display.mdAndUp" justify="center" align="stretch" dense>
                <v-col
                    v-for="service in filteredServices"
                    :key="service._id"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    class="d-flex justify-center"
                >
                    <v-card variant="tonal" color="primary" class="pa-3 d-flex flex-row align-center">
                        <v-icon size="large" class="mr-3">{{ service.icon }}</v-icon>
                        <span class="text-subtitle2 font-weight-bold" style="text-transform: uppercase">
                            {{ service.title }}
                        </span>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Mobile Ansicht: Carousel mit mehreren Karten pro Slide -->
            <v-carousel class="mb-5" height="420px" v-else :show-arrows="false" hide-delimiter-background>
                <v-carousel-item v-for="(group, index) in groupedServices" :key="'service-group-' + index">
                    <v-row justify="center" align="stretch" dense>
                        <v-col v-for="service in group" :key="service._id" cols="12" class="d-flex justify-center">
                            <v-card variant="tonal" color="primary" class="pa-3 d-flex flex-row align-center">
                                <v-icon size="large" class="mr-3">{{ service.icon }}</v-icon>
                                <span class="text-subtitle2 font-weight-bold" style="text-transform: uppercase">
                                    {{ service.title }}
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        props: {
            offersAndServices: {
                type: Array,
                required: true,
            },
        },
        computed: {
            filteredOffers() {
                return this.offersAndServices.filter((item) => item.isOffer)
            },
            filteredServices() {
                return this.offersAndServices.filter((item) => !item.isOffer)
            },
            // Gruppiere Angebote für das Carousel (z.B. 2 pro Gruppe auf Mobile)
            groupedOffers() {
                return this.groupItems(this.filteredOffers, 6)
            },
            // Gruppiere Leistungen für das Carousel (z.B. 2 pro Gruppe auf Mobile)
            groupedServices() {
                return this.groupItems(this.filteredServices, 6)
            },
        },
        methods: {
            groupItems(items, groupSize) {
                const groups = []
                for (let i = 0; i < items.length; i += groupSize) {
                    groups.push(items.slice(i, i + groupSize))
                }
                return groups
            },
        },
    }
</script>

<style scoped>
    .v-card {
        width: 100%; /* Karten sollen volle Spaltenbreite ausfüllen */
    }
    .v-icon {
        color: #4caf50; /* Icon-Farbe */
    }
    .v-card-title {
        font-weight: bold;
    }
    @media (max-width: 960px) {
        /* md breakpoint von Vuetify */
        .v-carousel-item {
            padding: 0 8px; /* Optional: Abstand zwischen Carousel-Items */
        }
    }

    :deep(.v-carousel__controls__item) {
        background-color: rgba(0, 0, 0, 0.5); /* Dunklere Dots für bessere Sichtbarkeit */
        width: 12px; /* Größere Dots */
        height: 12px;
        margin: 0 6px; /* Mehr Abstand zwischen den Dots */
        transition: background-color 0.3s, transform 0.3s;
    }

    :deep(.v-carousel__controls__item:hover) {
        transform: scale(1.2); /* Vergrößere den Dot bei Hover */
    }

    :deep(.v-carousel__controls__item.v-btn--active) {
        background-color: #4caf508f; /* Farbe des aktiven Dots */
        width: 14px; /* Größere aktive Dot */
        height: 14px;
    }
</style>
