<template>
    <div class="snow-container">
        <div v-for="flake in snowflakes" :key="flake.id" class="snowflake" :style="flake.style"></div>
    </div>
</template>

<script>
    export default {
        name: 'Snowfall',
        data() {
            return {
                snowflakes: [],
            }
        },
        methods: {
            generateSnowflakes() {
                const flakes = []
                for (let i = 0; i < 300; i++) {
                    flakes.push({
                        id: i,
                        style: {
                            left: Math.random() * 100 + 'vw',
                            animationDelay: Math.random() * 5 + 's',
                            animationDuration: Math.random() * 5 + 5 + 's',
                        },
                    })
                }
                this.snowflakes = flakes
            },
        },
        mounted() {
            this.generateSnowflakes()
        },
    }
</script>

<style scoped>
    .snow-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 9999;
        overflow: hidden;
    }

    .snowflake {
        position: absolute;
        top: -10px;
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 50%;
        opacity: 0.8;
        animation-name: fall;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    @keyframes fall {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(100vh);
        }
    }
</style>
