<template>
    <div class="custom-background pa-0 d-flex justify-center align-center">
        <v-card
            :style="{ maxWidth: dynamicMaxWidth, margin: dynamicPadding }"
            class="custom-background-card rounded-lg d-flex justify-center"
            min-height="300px"
            height="25vh"
            elevation="0"
        >
            <div class="my-auto d-flex flex-column my-auto">
                <v-card-title class="text-center custom-white-text text-h5 font-weight-bold text-uppercase">
                    11 TAGE
                    <br />
                    KOSTENLOS TRAINIEREN
                </v-card-title>
                <v-card-text class="custom-white-text text-center">
                    Teste unser Fitnessstudio und entdecke unsere Kurse, Trainingspläne und 24/7 Trainingsmöglichkeiten.
                </v-card-text>
                <v-btn @click="showPopup = true" class="mx-auto" color="primary" style="min-width: auto">
                    Jetzt ausprobieren
                </v-btn>
            </div>
        </v-card>
        <v-dialog v-model="showPopup" persistent max-width="500">
            <v-card>
                <v-card-title v-show="false" class="font-weight-bold" style="background-color: #00963f; color: white">
                    11 Tage kostenlos trainieren
                </v-card-title>

                <v-stepper flat v-model="step">
                    <v-stepper-header v-show="false">
                        <v-stepper-item color="primary" :complete="step > 0" step="1">Studio</v-stepper-item>

                        <v-stepper-item color="primary" :complete="step > 1" step="2">Infos</v-stepper-item>

                        <v-stepper-item color="primary" :complete="step > 2" step="3">Abschluss</v-stepper-item>
                    </v-stepper-header>

                    <v-stepper-window>
                        <!-- Step 1: Studio Auswahl -->
                        <v-stepper-window-item :value="0">
                            <p class="mb-4">
                                Profitiere von unserem 11-tägigen GRATIS-ANGEBOT. Lass uns wissen, wer du bist, damit
                                wir dich kontaktieren und einen ersten Termin vereinbaren können.
                            </p>
                            <v-autocomplete
                                v-if="!formData.selectedStudio"
                                v-model="formData.selectedStudio"
                                max-width="500px"
                                label="Studio auswählen"
                                :items="filteredStudios"
                                item-title="name"
                                rounded
                                flat
                                clearable
                                autocomplete="nope"
                                item-value="code"
                                style="background-color: hsla(0, 0%, 100%, 0.08)"
                                append-inner-icon="mdi-map-marker"
                                placeholder="Suche nach Kanton, Stadt oder PLZ"
                                variant="solo-filled"
                                class="custom-autocomplete mx-auto"
                                hide-details
                                :rules="[rules.required]"
                                no-filter
                                @input="onInput"
                            >
                                <template v-slot:item="{ item, index }">
                                    <v-list-item
                                        @click="formData.selectedStudio = item.value"
                                        :class="{
                                            'border-bottom-except-last': index + 1 !== filteredStudios.length,
                                        }"
                                    >
                                        <v-list-item-title>
                                            {{ getStudioInfosByCode(item.value).name }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle class="mb-1">
                                            {{ getStudioInfosByCode(item.value).adresse.split(',')[0] }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="mb-1">
                                            {{ getStudioInfosByCode(item.value).adresse.split(',')[1] }}
                                        </v-list-item-subtitle>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <div v-else class="d-flex justify-center flex-column text-center">
                                <v-card-subtitle
                                    style="color: #00963f; opacity: 1"
                                    class="pb-0 font-weight-bold text-h5"
                                >
                                    {{ getStudioInfosByCode(formData.selectedStudio).name.toUpperCase() }}
                                </v-card-subtitle>
                                <v-card-text class="pb-0 pt-0">
                                    {{ getStudioInfosByCode(formData.selectedStudio).adresse.split(',')[0] }}
                                </v-card-text>
                                <v-card-text class="pb-0 pt-0">
                                    {{ getStudioInfosByCode(formData.selectedStudio).adresse.split(',')[1] }}
                                </v-card-text>
                                <v-btn variant="flat" @click="formData.selectedStudio = null">Studio wechseln</v-btn>
                            </div>
                            <v-card-actions class="px-5 pb-5">
                                <v-btn variant="text" @click="hidePopup">Abbrechen</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="!formData.selectedStudio"
                                    variant="elevated"
                                    color="primary"
                                    @click="step = 1"
                                    class="mt-4"
                                >
                                    Weiter
                                </v-btn>
                            </v-card-actions>
                        </v-stepper-window-item>

                        <!-- Step 2: Persönliche Daten -->
                        <v-stepper-window-item :value="1">
                            <v-form ref="form" v-model="formValid">
                                <v-text-field
                                    rounded
                                    class="pb-2"
                                    flat
                                    hide-details
                                    v-model="formData.firstName"
                                    label="Vorname"
                                    required
                                    variant="solo-filled"
                                    dense
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-text-field
                                    rounded
                                    class="pb-2"
                                    flat
                                    hide-details
                                    v-model="formData.lastName"
                                    label="Nachname"
                                    required
                                    variant="solo-filled"
                                    dense
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-text-field
                                    rounded
                                    flat
                                    class="pb-2"
                                    hide-details
                                    v-model="formData.email"
                                    label="E-Mail"
                                    required
                                    variant="solo-filled"
                                    dense
                                    type="email"
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                                <v-text-field
                                    rounded
                                    class="pb-2"
                                    flat
                                    hide-details
                                    v-model="formData.phone"
                                    label="Telefonnummer"
                                    required
                                    variant="solo-filled"
                                    dense
                                    type="tel"
                                    :rules="[rules.required]"
                                ></v-text-field>
                                <v-textarea
                                    rounded
                                    hide-details
                                    flat
                                    v-model="formData.message"
                                    label="Notizen"
                                    required
                                    variant="solo-filled"
                                    dense
                                    type="text"
                                ></v-textarea>
                                <v-card-actions>
                                    <v-btn variant="text" @click="step = 0">Zurück</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        variant="elevated"
                                        color="primary"
                                        @click="step = 2"
                                        :disabled="!formValid"
                                        class="mt-4"
                                    >
                                        Weiter
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-stepper-window-item>

                        <!-- Step 3: Bestätigung -->
                        <v-stepper-window-item :value="2">
                            <v-card-text>
                                <p>
                                    <strong>Studio:</strong>
                                    {{ getStudioInfosByCode(formData.selectedStudio).name }}
                                    <br />

                                    <strong>Name:</strong>
                                    {{ formData.firstName }} {{ formData.lastName }}
                                    <br />
                                    <strong>E-Mail:</strong>
                                    {{ formData.email }}
                                    <br />
                                    <strong v-if="formData.phone && formData.phone.length > 3">Telefonnummer:</strong>
                                    {{ formData.phone }}
                                    <br />
                                    <strong v-if="formData.message && formData.message.length > 3">Notizen:</strong>
                                    {{ formData.message }}
                                </p>
                                <p class="pt-5">
                                    Mit dem Absenden bestätigst du, dass wir dich kontaktieren dürfen. Weitere
                                    Informationen findest du in unseren
                                    <a style="color: #00963f" href="/agb" target="_blank">AGB</a>
                                    und unserer
                                    <a style="color: #00963f" href="/datenschutz" target="_blank">
                                        Datenschutzerklärung.
                                    </a>
                                </p>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn variant="text" @click="step = 1">Zurück</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn variant="elevated" color="primary" @click="submitForm">Absenden</v-btn>
                            </v-card-actions>
                        </v-stepper-window-item>
                    </v-stepper-window>
                </v-stepper>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
                showPopup: false,
                step: 0,
                formValid: false,
                formData: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    selectedStudio: null,
                    message: '',
                },
                inputTimeout: null,
                rules: {
                    required: (value) => !!value || 'Dieses Feld ist erforderlich',
                    email: (value) =>
                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Bitte eine gültige E-Mail-Adresse eingeben',
                },
                search: '',

                filteredStudios: [],
            }
        },
        computed: {
            ...mapState({
                studios: (state) => state.studios,
                loadingStudios: (state) => state.loadingStudios,
                loadingStudiosError: (state) => state.loadingStudiosError,
            }),

            dynamicMaxWidth() {
                const { xs, sm, md, lg, xl, width } = this.$vuetify.display

                if (xs) return '85%'
                if (sm) return '80%'
                if (md) return '60%'
                if (lg) return '40%'
                if (xl && width <= 1920) return '40%' // Standard-xl
                if (width > 1920 && width <= 2560) return '30%' // Zwischen 1920px und 2560px
                if (width > 2560) return '25%' // Größer als 2560px (z.B. 5K)
                return '100%' // Fallback
            },
            dynamicPadding() {
                const { xs, sm, md, lg, xl, width } = this.$vuetify.display

                if (xs) return '70px 0px 70px 0px' // Kleine Geräte
                if (sm) return '100px 0px 100px 0px'
                if (md) return '100px 0px 100px 0px'
                if (lg) return '100px 0px 100px 0px'
                if (xl && width <= 1920) return '40px' // Standard-xl
                if (width > 1920 && width <= 2560) return '100px 0px 100px 0px' // Zwischen 1920px und 2560px
                if (width > 2560) return '120px 0px 120px 0px' // Größer als 2560px (z.B. 5K)
                return '16px' // Fallback
            },
        },
        methods: {
            ...mapActions(['fetchStudios']),
            hidePopup() {
                this.showPopup = false
                const updatedQuery = { ...this.$route.query }
                delete updatedQuery['gratis-training'] // Entferne den Parameter
                this.$router.push({ query: updatedQuery })
            },
            async submitForm() {
                if (this.$refs.form.validate()) {
                    // Daten senden, wenn alle Validierungen bestanden sind
                    try {
                        let leadEntry = {
                            studio: this.getStudioInfosByCode(this.formData.selectedStudio)._id,
                            customer: '5ffc504f26abe65228e36a21',
                            leadSetting: '6745de7072207c98c9c0cac0',
                            platform: '',
                            name: this.formData.firstName,
                            surname: this.formData.lastName,
                            phone: this.formData.phone,
                            email: this.formData.email,
                            message: this.formData.message,
                            status: 'Wurde angeworben',
                        }

                        let response = await this.$axios.post('https://app.wellcomefit.ch/api/websitelead', {
                            leadEntry,
                        })

                        if (response.status === 200) {
                            // Erfolgreiche Übermittlung behandeln
                            this.showPopup = false
                            this.$router.push({
                                name: 'thankyou',
                                query: {
                                    name: this.formData.firstName,
                                    surname: this.formData.lastName,
                                    email: this.formData.email,
                                    phone: this.formData.phone,
                                    message: this.formData.message,
                                    studio: this.getStudioInfosByCode(this.formData.selectedStudio).name,
                                },
                            })
                        } else {
                            throw new Error('Fehler beim Senden des Formulars.')
                        }
                    } catch (error) {
                        console.error(error)
                        this.errors.push('Es gab ein Problem bei der Übermittlung. Bitte versuche es später erneut.')
                    }
                }
            },
            onInput(val) {
                clearTimeout(this.inputTimeout)
                this.inputTimeout = setTimeout(() => {
                    this.search = val.target.value
                }, 300)
            },
            filterStudios() {
                if (!this.search) {
                    this.filteredStudios = this.studios
                    return
                }
                const searchLower = this.search.toLowerCase()
                this.filteredStudios = this.studios.filter((studio) => {
                    return (
                        studio.canton.toLowerCase().includes(searchLower) ||
                        studio.art.toLowerCase().includes(searchLower) ||
                        studio.name.toLowerCase().includes(searchLower) ||
                        studio.adresse.toLowerCase().includes(searchLower) ||
                        studio.email.toLowerCase().includes(searchLower) ||
                        studio.code.toLowerCase().includes(searchLower)
                    )
                })
            },
            getStudioInfosByCode(code) {
                let studioInfos = {
                    name: '',
                    adresse: '',
                    _id: '',
                }
                let studio = this.studios.find((e) => e.code === code)
                if (!studio) return studioInfos
                return { name: studio.name, adresse: studio.adresse, _id: studio._id }
            },
        },
        watch: {
            '$route.query': {
                handler: function (query) {
                    if (query['gratis-training']) {
                        this.showPopup = true
                    } else {
                        this.showPopup = false // Optional: Setze es zurück, wenn `gratis-training` entfernt wird
                    }
                },
                immediate: true,
                deep: true,
            },
            search() {
                this.filterStudios()
            },
        },
        async created() {
            await this.fetchStudios()
            this.filterStudios()
        },
    }
</script>

<style scoped>
    .custom-background {
        width: 100%;

        background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 50%, transparent 70%)
                center center / 400px 400px no-repeat,
            repeating-conic-gradient(from 0deg, #00963f 0deg, #00963f 20deg, #4cb278 20deg, #4cb278 40deg) center center /
                100% 100%;
        background-color: #00963f;
    }
    .custom-background-card {
        background-color: rgba(26, 26, 26, 0.6);
        height: 90%;
        width: 90%;
    }
    .custom-autocomplete {
        background-color: white !important;
        color: black;
    }
    .custom-white-text {
        color: white;
        line-break: auto;
    }
</style>
