<template>
    <v-card
        max-width="700"
        v-if="studio && studio.code"
        class="px-0 pb-0 mx-auto d-flex justify-center flex-column"
        flat
    >
        <v-card-title v-show="$vuetify.display.smAndDown" class="text-h6 pb-0">Stosszeiten</v-card-title>

        <!-- Day Selector -->
        <v-btn-toggle class="mt-5 mx-auto" v-model="selectedDay" mandatory color="primary">
            <v-btn
                :class="{ 'selected-day': day === selectedDay }"
                :size="$vuetify.display.smAndDown ? 'small' : 'large'"
                v-for="(day, index) in days"
                :key="index"
                @click="updateChart(day)"
            >
                {{ day }}
            </v-btn>
        </v-btn-toggle>

        <!-- Bar Chart for Hours -->
        <apexchart ref="chart" type="bar" height="150" :options="chartOptions" :series="seriesData"></apexchart>
        <v-card-subtitle class="mt-0" :class="$vuetify.display.smAndDown ? '' : 'mb-5'">
            Metrik basierend auf der durchschnittlichen Aktivität
        </v-card-subtitle>
    </v-card>
</template>

<script>
    import VueApexCharts from 'vue3-apexcharts'

    export default {
        props: {
            studio: {
                type: Object,
                default: null,
                required: true,
            },
        },
        components: {
            apexchart: VueApexCharts,
        },
        data() {
            return {
                days: ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'],
                selectedDay: null, // Default day
                chartOptions: {
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false, // Hide the hamburger menu (toolbar)
                        },
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '90%',
                            distributed: true,
                            borderRadius: 7, // Increase the border radius for better roundness
                            borderRadiusApplication: 'around', // Applies rounding to both ends equally
                            dataLabels: {
                                enabled: false, // Disable data labels inside bars
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false, // Ensure data labels are globally disabled
                    },
                    xaxis: {
                        categories: [
                            '5 Uhr',
                            '6 Uhr',
                            '7 Uhr',
                            '8 Uhr',
                            '9 Uhr',
                            '10 Uhr',
                            '11 Uhr',
                            '12 Uhr',
                            '13 Uhr',
                            '14 Uhr',
                            '15 Uhr',
                            '16 Uhr',
                            '17 Uhr',
                            '18 Uhr',
                            '19 Uhr',
                            '20 Uhr',
                            '21 Uhr',
                            '22 Uhr',
                            '23 Uhr',
                        ],
                        labels: {
                            rotate: 0, // Ensures the labels are horizontal
                            formatter: function (val, index) {
                                if (index === 1 || index === 7 || index === 13 || index === 18) {
                                    return val
                                } else {
                                    return ''
                                }
                            },
                            style: {
                                fontSize: '12px',
                            },
                        },
                        axisBorder: {
                            show: false, // Hide x-axis border line
                        },
                        axisTicks: {
                            show: false, // Hide x-axis ticks
                        },
                    },
                    yaxis: {
                        max: 40, // Adjust max if needed based on your data
                        labels: {
                            show: false, // Hide the Y-axis labels
                        },
                        axisBorder: {
                            show: false, // Hide y-axis border line
                        },
                        axisTicks: {
                            show: false, // Hide y-axis ticks
                        },
                    },
                    grid: {
                        show: false, // Hide grid lines
                    },
                    tooltip: {
                        x: {
                            formatter: function (val) {
                                return `${val}` // Show the full label on hover
                            },
                        },
                        y: {
                            title: {
                                formatter: function () {
                                    return ''
                                },
                            },
                            formatter: function (val) {
                                if (val <= 10) {
                                    return 'Nicht zu stark besucht'
                                } else if (val <= 25) {
                                    return 'Mässig besucht'
                                } else if (val <= 40) {
                                    return 'Normalerweise am stärksten besucht'
                                } else {
                                    return '' // In case val goes beyond the expected range
                                }
                            },
                        },
                    },
                    title: {
                        text: '', // Remove the chart title
                    },
                    legend: {
                        show: false, // Hide the legend
                    },
                },
                seriesData: [],
            }
        },
        created() {
            this.fetchPopularTimes()
        },
        methods: {
            async fetchPopularTimes() {
                try {
                    let status = await this.$axios.get('/website/checkin/' + this.studio.code)
                    if (status.status === 200) {
                        this.data = status.data
                        const daysMapping = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
                        const todayIndex = new Date().getDay()
                        this.selectedDay = daysMapping[todayIndex]

                        this.setChartColors() // Set the chart colors once the data is loaded

                        this.updateChart(this.selectedDay)
                    }
                } catch (e) {
                    console.error('Fehler beim Abrufen der Daten', e)
                }
            },
            setChartColors() {
                const currentHour = new Date().getHours()
                const colors = []

                // Set the color for each bar based on the current hour
                for (let i = 5; i <= 23; i++) {
                    if (i === currentHour) {
                        colors.push('#313131') // Highlight current hour in dark gray
                    } else {
                        colors.push('#00963f') // Default color for other bars
                    }
                }

                this.$refs.chart.updateOptions({
                    colors: colors,
                })
            },
            updateChart(day) {
                const filteredData = []
                const dayData = this.data[day]

                // Loop through each hour from 5 AM to 12 AM (midnight)
                for (let i = 5; i <= 23; i++) {
                    filteredData.push(dayData[i])
                }

                // Set the series data
                this.seriesData = [
                    {
                        name: day,
                        data: filteredData,
                    },
                ]
            },
        },
    }
</script>

<style scoped>
    .v-card {
        padding: 16px;
    }

    .selected-day {
        background-color: #00963f !important; /* Change the background color of the selected day */
        color: white !important; /* Ensure the text color contrasts well */
    }
</style>
