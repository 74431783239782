<template>
    <v-container style="margin-top: 64px" class="mb-5 d-flex align-center">
        <v-card max-width="1200px" class="pb-4 mt-5 mx-auto" flat>
            <v-row>
                <v-col cols="12">
                    <v-img class="rounded-pill mx-auto" width="300px" height="300px" cover :src="tunc"></v-img>
                </v-col>
                <v-col cols="12">
                    <v-card-title class="text-h5 text-center">
                        Willkommen bei der well come FIT Group – Deine Reise zu einem aktiveren Leben beginnt hier!
                    </v-card-title>

                    <v-card-text class="px-0">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-justify">
                                        Wir träumen von einer Welt, in der Fitness und Wohlbefinden für alle ein fester
                                        Bestandteil des Alltags sind. Bei der well come FIT Group schaffen wir Räume,
                                        die inspirieren, motivieren und Menschen dabei unterstützen, ihre beste Version
                                        zu werden. Gemeinsam gestalten wir eine Zukunft, in der Gesundheit nicht nur ein
                                        Ziel, sondern eine Lebenseinstellung ist.
                                    </p>
                                </v-col>
                                <v-col cols="12">
                                    <p class="text-justify">
                                        Unser Antrieb ist es, Fitness in all seinen Facetten zugänglich, spannend und
                                        bezahlbar zu machen. Mit einem Mix aus modernster Ausstattung, persönlicher
                                        Betreuung und einem Hauch von Leidenschaft machen wir Bewegung zu einem
                                        Erlebnis, das begeistert.
                                    </p>
                                </v-col>
                                <v-col cols="12">
                                    <p class="text-justify">
                                        <strong>Unser Ziel:</strong>
                                        Jeder und jede soll die Chance haben, den ersten Schritt zu einem gesünderen
                                        Leben zu machen – und wir sind an seiner Seite.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex mb-4 align-center">
                                        <v-icon color="primary" class="me-2">mdi-lightbulb-outline</v-icon>
                                        <div style="margin-left: 10px; text-align: left">
                                            <strong>Inspiration statt Routine:</strong>
                                            Bei uns geht es nicht nur um Training, sondern darum, Spass an Bewegung zu
                                            finden. Jeder Besuch bei der well come FIT Group soll ein Highlight des
                                            Tages sein.
                                        </div>
                                    </div>
                                    <div class="d-flex mb-4 align-center">
                                        <v-icon color="primary" class="me-2">mdi-account-multiple</v-icon>
                                        <div style="margin-left: 10px; text-align: left">
                                            <strong>Für alle, die bereit sind:</strong>
                                            Egal ob Anfänger oder bereits fortgeschritten – wir heissen alle willkommen
                                            und unterstützen dich mit Expertise und Herzblut.
                                        </div>
                                    </div>
                                    <div class="d-flex mb-4 align-center">
                                        <v-icon color="primary" class="me-2">mdi-handshake</v-icon>
                                        <div style="margin-left: 10px; text-align: left">
                                            <strong>Gemeinschaft, die verbindet:</strong>
                                            Unsere Fitnesscenter sind mehr als nur Orte zum Trainieren. Sie sind
                                            Treffpunkte, an denen Gleichgesinnte sich gegenseitig motivieren und Erfolge
                                            teilen.
                                        </div>
                                    </div>
                                    <div class="d-flex mb-4 align-center">
                                        <v-icon color="primary" class="me-2">mdi-trophy-outline</v-icon>
                                        <div style="margin-left: 10px; text-align: left">
                                            <strong>Qualität, die überzeugt:</strong>
                                            Von den besten Geräten bis hin zu den erfahrensten Trainern – wir stehen für
                                            höchste Standards, ohne dabei dein Budget zu sprengen.
                                        </div>
                                    </div>
                                    <div class="d-flex mb-4 align-center">
                                        <v-icon color="primary" class="me-2">mdi-earth</v-icon>
                                        <div style="margin-left: 10px; text-align: left">
                                            <strong>Diversity, die feiert:</strong>
                                            Unterschiedlich und doch vereint – bei uns spielt es keine Rolle, woher du
                                            kommst oder wie deine Fitnessreise aussieht. Du bist hier richtig, so wie du
                                            bist.
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-justify">
                                        <strong>Unser Versprechen:</strong>
                                        <br />
                                        Wir sind mehr als nur ein Fitnessanbieter. Wir sind dein Partner, deine
                                        Motivation und deine Community. Mit uns entdeckst du, was in dir steckt – für
                                        ein Leben voller Energie, Stärke und Lebensfreude.
                                    </p>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <p>
                                        <strong>Mach den ersten Schritt – wir begleiten dich auf deinem Weg.</strong>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-col>
                <v-col cols="12">
                    <ClubMap />
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
    import tunc from '@/assets/tunc_karapalanci.jpg'
    import ClubMap from '@/components/ClubMap.vue'
    export default {
        beforeRouteEnter(to, from, next) {
            document.title = 'Über well come FIT group: Qualität & Erfahrung'
            const metaDescription = document.querySelector('meta[name="description"]')
            if (metaDescription) {
                metaDescription.setAttribute(
                    'content',
                    'Erfahre mehr über die well come FIT group, gegründet 1993 in Frauenfeld. Entdecke unsere 21 Standorte in der Schweiz und unser Engagement für Qualität und Fitness.'
                )
            }
            next()
        },
        components: { ClubMap },
        data() {
            return {
                tunc,
            }
        },
    }
</script>
