<template>
    <v-container style="margin-top: 64px">
        <v-card variant="flat" class="mx-auto">
            <v-card-title style="word-break: auto-phrase; line-height: 1.2" class="text-h4 mt-5">
                Aktuelle Stellenangebote
                <v-chip v-if="!loading">{{ mappedJobs.length }}</v-chip>
            </v-card-title>
            <v-card-text class="pb-0">
                Du liebst Fitness, arbeitest gerne mit Menschen und möchtest Deine Leidenschaft zum Beruf machen? Bei
                well come FIT bieten wir Dir die Chance auf einen Traumjob an unseren Standorten in der ganzen Schweiz.
                Unterstütze unsere Mitglieder auf ihrem Weg zu einem gesunden, aktiven Lebensstil und werde Teil eines
                motivierten Teams!
                <br />
                <br />
                Jetzt bewerben und gemeinsam mehr als Fitness erleben!
            </v-card-text>
        </v-card>

        <!-- No Jobs Available -->
        <v-row class="mt-5" v-if="mappedJobs.length === 0 && !loading">
            <v-col cols="12">
                <v-alert type="info" color="primary">Aktuell keine offenen Stellenangebote</v-alert>
            </v-col>
        </v-row>

        <!-- Jobs and Filters -->
        <v-row class="mt-5" v-if="!loading && mappedJobs.length > 0">
            <!-- Filter Panel -->
            <v-col cols="12" sm="4">
                <v-card-title class="px-0 pt-0 text-start text-h6">Filter</v-card-title>
                <v-select
                    variant="outlined"
                    :items="studios"
                    item-title="name"
                    hide-details
                    item-value="_id"
                    multiple
                    v-model="selectedStudios"
                    label="Studios"
                />
                <v-btn class="mb-2 mt-1" @click="toggleAllStudios" variant="text">
                    <span v-if="selectedStudios.length === 0">Alle anwählen</span>
                    <span v-else>Alle abwählen</span>
                </v-btn>
                <v-card-subtitle v-if="durationTypes.length > 0" class="text-start px-0">
                    Art der Anstellung
                </v-card-subtitle>
                <v-row no-gutters>
                    <v-col v-for="(durationType, i) of durationTypes" cols="12" :key="'duration-' + i">
                        <v-checkbox
                            hide-details
                            color="primary"
                            class="py-0 my-0"
                            :model-value="selectedDurationTypes.includes(durationType)"
                            :label="durationType"
                            @update:modelValue="(checked) => toggleDurationType(durationType, checked)"
                        />
                    </v-col>
                </v-row>
                <v-card-subtitle v-if="jobTitles.length > 0" class="text-start px-0 pt-0">Bezeichnung</v-card-subtitle>
                <v-row no-gutters>
                    <v-col v-for="(jobTitle, i) of jobTitles" cols="12" :key="'title-' + i">
                        <v-checkbox
                            color="primary"
                            hide-details
                            class="py-0 my-0 text-start"
                            :model-value="selectedJobTitles.includes(jobTitle)"
                            :label="jobTitle"
                            @update:modelValue="(checked) => toggleTitleType(jobTitle, checked)"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <!-- Job Cards -->
            <v-col cols="12" sm="8">
                <JobCards v-if="filteredJobs.length > 0" class="mb-5" :jobs="filteredJobs" />
                <v-alert v-else color="primary" type="info">Keine Stellenangebote gefunden</v-alert>
            </v-col>
        </v-row>

        <!-- Loading State -->
        <v-row class="mt-5" v-if="loading">
            <v-col class="text-center d-flex flex-column justify-center" cols="12">
                <span>Stellenangebote werden geladen</span>
                <v-progress-circular class="mx-auto mt-5" indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import JobCards from '@/components/JobCards.vue'

    export default {
        components: { JobCards },
        data() {
            return {
                loading: true,
                mappedJobs: [],
                studios: [],
                selectedStudios: [],
                durationTypes: [],
                jobTitles: [],
                selectedJobTitles: [],
                selectedDurationTypes: [],
            }
        },
        created() {
            this.fetchJobs()
        },
        computed: {
            filteredJobs() {
                return this.mappedJobs.filter((job) => {
                    const matchStudio = this.selectedStudios.includes(job.studio?._id)
                    const matchDuration = this.selectedDurationTypes.includes(job.durationTitle)
                    const matchTitle = this.selectedJobTitles.includes(job.type.title)
                    return matchStudio && matchDuration && matchTitle
                })
            },
        },
        methods: {
            async fetchJobs() {
                this.loading = true
                try {
                    const response = await this.$axios.get('https://app.wellcomefit.ch/api/website/joboffers/active')
                    const jobs = response.data

                    this.mappedJobs = jobs.map((job) => {
                        if (!this.jobTitles.includes(job.type.title)) this.jobTitles.push(job.type.title)
                        if (
                            job.durationTitle &&
                            job.durationTitle.length > 2 &&
                            !this.durationTypes.includes(job.durationTitle)
                        )
                            this.durationTypes.push(job.durationTitle)
                        if (job.studio && !this.studios.some((studio) => studio._id === job.studio._id)) {
                            this.studios.push(job.studio)
                        }
                        return job
                    })

                    this.selectedStudios = this.studios.map((studio) => studio._id)
                    this.selectedJobTitles = [...this.jobTitles]
                    this.selectedDurationTypes = [...this.durationTypes]
                } catch (error) {
                    console.error('Error fetching jobs:', error)
                }
                this.loading = false
            },
            toggleDurationType(durationType, isChecked) {
                if (isChecked) {
                    this.selectedDurationTypes.push(durationType)
                } else {
                    this.selectedDurationTypes = this.selectedDurationTypes.filter((type) => type !== durationType)
                }
            },
            toggleTitleType(title, isChecked) {
                if (isChecked) {
                    this.selectedJobTitles.push(title)
                } else {
                    this.selectedJobTitles = this.selectedJobTitles.filter((t) => t !== title)
                }
            },
            toggleAllStudios() {
                if (this.selectedStudios.length === 0) {
                    this.selectedStudios = this.studios.map((studio) => studio._id)
                } else {
                    this.selectedStudios = []
                }
            },
        },
    }
</script>
