import { createApp } from 'vue'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import moment from 'moment'
import 'moment/locale/de-ch' // Importiere das Schweizerdeutsche Locale
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosPlugin from './axiosPlugin' // Importiere das Axios-Plugin

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // Setze das Standard-Icon-Set auf 'mdi'
    },
    theme: {
        themes: {
            light: {
                colors: {
                    darkbackground: '#343541',
                    //primary: '#00963f',
                    primary: '#00963f',
                    lightenprimary: '#c2e2b7',
                    darkenprimary: '#346f20',
                    darkenprimary2: '#2c5f1c',
                    alibey: '#0d7e9e',
                    listitemcolor: '#e9f4f5',
                    habitBlue: '#4bb2fd',
                    habitLightBlue: '#c2e2f7',
                    habitLightGreen: '#f4fdf2',
                    habitCompleted: '#c2e2b7',
                    habitDelayed: '#ffcccc',
                    protein: '#0096DD',
                    fat: '#FFA500',
                    carbs: '#F62F49',
                },
            },
        },
    },
})

moment.locale('de-ch') // Setze das Locale auf Schweizerdeutsch
const app = createApp(App)

app.config.globalProperties.$moment = moment

app.use(router)
app.use(store)
app.use(vuetify)
app.use(axiosPlugin) // Verwende das Axios-Plugin

app.mount('#app')
