<template>
    <v-container>
        <v-row class="d-flex align-center" :style="{ padding: dynamicPadding }">
            <!-- Text Section -->
            <v-col cols="12" md="6" class="d-flex flex-column align-center">
                <v-card color="transparent" flat class="mx-auto">
                    <v-card-title class="text-h5">Fitness</v-card-title>
                    <v-card-text class="font-weight-thin">
                        Willkommen im well come FIT. Deinem Fitnesscenter! Trainiere mit top ausgebildeten Trainer:innen
                        an modernsten Geräten. Erreiche mit uns deine Ziele in Fitness, Muskelaufbau und Gesundheit.
                        Starte noch heute mit deiner Fitnessreise.
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Carousel Section -->
            <v-col cols="12" md="6" class="d-flex flex-column align-center">
                <v-carousel
                    class="rounded-lg mt-3 mx-auto"
                    :show-arrows="false"
                    delimiter-icon="mdi-square"
                    hide-delimiter-background
                    cycle
                    height="auto"
                >
                    <v-carousel-item :src="bild1" aspect-ratio="16/9"></v-carousel-item>
                    <v-carousel-item :src="bild2" aspect-ratio="16/9"></v-carousel-item>
                    <v-carousel-item :src="bild3" aspect-ratio="16/9"></v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import bild1 from '@/assets/images/fitness/1.jpg'
    import bild2 from '@/assets/images/fitness/2.jpg'
    import bild3 from '@/assets/images/fitness/3.jpg'

    export default {
        data() {
            return {
                bild1,
                bild2,
                bild3,
            }
        },
        computed: {
            dynamicPadding() {
                const { xs, sm, md, lg, xl, width } = this.$vuetify.display

                if (xs) return '0px  0px 70px 0px' // Kleine Geräte
                if (sm) return '30px  0px 70px 0px'
                if (md) return '30px  0px 70px 0px'
                if (lg) return '30px  0px 70px 0px'
                if (xl && width <= 1920) return '30px  0px 70px 0px' // Standard-xl
                if (width > 1920 && width <= 2560) return '30px  0px 70px 0px' // Zwischen 1920px und 2560px
                if (width > 2560) return '30px  0px 70px 0px' // Größer als 2560px (z.B. 5K)
                return '30px  0px 70px 0px' // Fallback
            },
        },
    }
</script>

<style>
    /* Optional: Anpassung für mehr Abstände auf größeren Bildschirmen */
    @media (min-width: 960px) {
        .v-col {
            padding: 20px;
        }
    }
</style>
