<template>
    <v-card flat class="px-0 pb-10">
        <v-card-title class="text-h5">Dein Team</v-card-title>
        <div v-if="employeeList.length" class="scrolling-row" ref="scrollingRow">
            <v-col
                cols="auto"
                v-for="(employee, index) in employeeList.filter((emp) => emp.picture)"
                :key="index"
                class="team-card mx-0"
            >
                <v-card style="min-height: 150px" variant="flat" class="rounded-xl">
                    <v-img
                        lazy-src="https://media.istockphoto.com/id/1337144146/de/vektor/standard-avatar-profilsymbolvektor.jpg?s=612x612&w=0&k=20&c=rw3jpsQAdTti6Hh9SAGN15Ex8HYYwmHv3JFHS6KStMo="
                        style="width: 200px; height: 100%; min-height: 20px"
                        :src="'https://app.wellcomefit.ch/img/profilbilder/' + employee.picture"
                        contain
                    ></v-img>
                    <div class="text-white team-card__emp rounded-xl">
                        <span style="font-size: 14px" class="h4 pt-2 px-2 pb-1">{{ employee.fullName }}</span>
                        <span style="font-size: 12px" class="h6 pb-2 px-2">{{ employee.title }}</span>
                    </div>
                </v-card>
            </v-col>
        </div>
        <div v-else class="scrolling-row">
            <v-col cols="auto" v-for="n in 5" :key="'employee-skeleton' + n" class="team-card">
                <v-skeleton-loader
                    type="card"
                    style="min-height: 150px; min-width: 200px"
                    class="rounded-xl"
                ></v-skeleton-loader>
            </v-col>
        </div>
    </v-card>
</template>
<script>
    export default {
        props: {
            selectedStudio: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                employeeList: [],
                isPaused: false,
                selected: true,
                loading: true,
                selectedOpeningHours: '',
                touchStartX: 0,
                touchEndX: 0,
                isScrolling: false,
                scrollDirection: 1, // 1 for left, -1 for right
            }
        },

        async created() {
            if (this.selectedStudio) {
                try {
                    let employee = await this.$axios.get('employee/fetch/studio/' + this.selectedStudio.code)
                    if (employee.status === 200 && employee.data) {
                        this.employeeList = employee.data
                    }
                } catch (e) {
                    console.error('Error fetching employee data:', e)
                } finally {
                    this.loading = false
                }
            }
        },
    }
</script>
<style lang="scss">
    .team-section {
        overflow: hidden;
        white-space: nowrap;
        position: relative;
    }

    .scrolling-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow-x: auto; /* Ensure horizontal scrolling */
        scroll-behavior: smooth; /* Smooth scrolling */

        @media only screen and (min-width: 1000px) {
            flex-wrap: wrap !important;
        }
    }

    .team-card {
        flex: 0 0 auto;
        margin: 0 10px;

        @media only screen and (min-width: 1000px) {
            padding: 5px !important;

            .v-card {
                border-radius: 8px !important;
            }
        }
    }

    .team-card__emp-image {
        height: 100% !important;
    }

    .team-card__emp {
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #949594c2;
    }
</style>
