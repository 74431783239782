<template>
    <v-card max-width="1000px" class="mx-auto text-left" style="margin-top: 64px" variant="flat">
        <v-card-title class="mt-5">Impressum</v-card-title>
        <v-card-subtitle>Inhalt und Verantwortung</v-card-subtitle>
        <v-card-text>
            well come Fit AG
            <br />
            Walzmühlestrasse 50
            <br />
            8500 Frauenfeld
            <br />
        </v-card-text>
        <v-card-subtitle>Kontakt</v-card-subtitle>
        <v-card-text>
            Telefon: +41 52 721 36 96
            <br />
            Email: info@wellcomefit.ch
        </v-card-text>
        <v-card-subtitle>Haftung</v-card-subtitle>
        <v-card-text>
            Wir übernehmen keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
            Zuverlässigkeit und Vollständigkeit der Informationen. Haftungsansprüche gegen uns wegen Schäden materieller
            oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten
            Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden
            ausgeschlossen. Alle Angebote sind unverbindlich. Wir behalten uns ausdrücklich vor, Teile der Seiten oder
            das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
            Veröffentlichung zeitweise oder endgültig einzustellen.
        </v-card-text>
        <v-card-subtitle>Verweise und Links</v-card-subtitle>
        <v-card-text>
            Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Der Zugriff und
            die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin. Wir erklären
            ausdrücklich, dass wir keinerlei Einfluss auf die Gestaltung, den Inhalt und die Angebote der verknüpften
            Seiten haben. Informationen und Dienstleistungen von verknüpften Webseiten liegen vollumfänglich in der
            Verantwortung des jeweiligen Dritten. Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
        </v-card-text>
        <v-card-subtitle>Copyright</v-card-subtitle>
        <v-card-text class="mb-5">
            Die dieser Website enthaltenen Informationen werden der Öffentlichkeit zugänglich gemacht. Durch das
            Herunterladen oder Kopieren von Inhalten, Bildern, Fotos oder anderen Dateien werden keinerlei Rechte
            bezüglich der Inhalte übertragen. Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
            anderen Dateien auf dieser Website gehören ausschliesslich uns oder explizit genannten Rechtsinhabern.
            Sämtliche Bilder und Fotos stammen aus eigenem Archiv oder aus öffentlichen Bildarchiven, mit welchen keine
            Rechte dritter verletzt werden. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der
            Urheberrechtsträger im Voraus einzuholen.
        </v-card-text>
    </v-card>
</template>
