<template>
    <div class="snow-container">
        <v-icon v-for="flake in snowflakes" :key="flake.id" class="snowflake" :style="flake.style">mdi-star</v-icon>
    </div>
</template>

<script>
    export default {
        name: 'Snowfall',
        data() {
            return {
                snowflakes: [],
            }
        },
        methods: {
            generateSnowflakes() {
                const flakes = []
                for (let i = 0; i < 200; i++) {
                    // Erhöhe die Anzahl der Icons
                    const size = Math.random() * 24 + 12 // Größe zwischen 12px und 36px
                    flakes.push({
                        id: i,
                        style: {
                            left: Math.random() * 100 + 'vw',
                            animationDelay: Math.random() * 5 + 's',
                            animationDuration: Math.random() * 5 + 7 + 's',
                            fontSize: size + 'px',
                            color: 'gold', // Goldene Farbe für die Icons
                            opacity: Math.random() * 0.6 + 0.4, // Opazität zwischen 0.4 und 1
                        },
                    })
                }
                this.snowflakes = flakes
            },
        },
        mounted() {
            this.generateSnowflakes()
        },
    }
</script>

<style scoped>
    .snow-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 9999;
        overflow: hidden;
    }

    .snowflake {
        position: absolute;
        top: -10px;
        animation-name: fall;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    @keyframes fall {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(100vh);
        }
    }
</style>
