<template>
    <v-card flat>
        <v-card-title v-if="!hideTitle" class="text-h4 pb-0">{{ studio.name }}</v-card-title>
        <v-row no-gutters>
            <v-col class="px-4" cols="12">
                {{ studio.adresse.split(',')[0] }}
                <br />
                {{ studio.adresse.split(',')[1] }}
            </v-col>
            <v-col v-if="studio.maps" class="mb-2 mt-0 px-4" cols="12">
                <v-btn
                    class="no-uppercase px-0 mx-0"
                    :ripple="false"
                    size="large"
                    color="primary"
                    height="30"
                    variant="text"
                    @click="openMaps"
                    :alt="`Standort ${studio.name} auf Google Maps ansehen`"
                >
                    <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
                    auf Karte ansehen
                </v-btn>
            </v-col>
            <v-col class="px-4" cols="12">
                <v-btn
                    class="no-uppercase px-0 mx-0"
                    :ripple="false"
                    size="large"
                    height="30"
                    variant="text"
                    @click="callStudio"
                    :alt="`Jetzt anrufen ${studio.telefon}`"
                >
                    <v-icon class="mr-1">mdi-phone-outline</v-icon>
                    {{ studio.telefon }}
                </v-btn>
            </v-col>
            <v-col class="px-4" cols="12">
                <v-btn
                    class="no-uppercase px-0 mx-0"
                    variant="text"
                    height="30"
                    size="large"
                    @click="emailStudio"
                    :alt="`Email an ${studio.email}`"
                >
                    <v-icon class="mr-1">mdi-email-outline</v-icon>
                    {{ studio.email }}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    export default {
        props: {
            studio: {
                type: Object,
                required: true,
            },
            hideTitle: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            callStudio() {
                window.location.href = `tel:${this.studio.telefon}`
            },
            emailStudio() {
                window.location.href = `mailto:${this.studio.email}`
            },
            openMaps() {
                window.open(this.studio.maps, '_blank')
            },
        },
    }
</script>

<style scoped>
    ::v-deep .no-uppercase {
        text-transform: unset !important;
        letter-spacing: normal !important;
    }
</style>
