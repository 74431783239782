<template>
    <v-navigation-drawer
        style="z-index: 10000"
        :temporary="true"
        :model-value="drawer"
        @update:modelValue="$emit('updateDrawer', $event)"
    >
        <v-list-item class="mb-1" title="well come FIT Group" subtitle="winti FIT & well come FIT"></v-list-item>
        <v-divider></v-divider>

        <v-list-item @click="openLink('/')" link title="Home"></v-list-item>
        <v-list-item @click="openLink('/clubs')" link title="Unsere Clubs"></v-list-item>
        <v-list-item @click="openLink('/kontakt')" link title="Kontakt"></v-list-item>
        <v-list-item @click="openLinkNewWindow('https://app.wellcomefit.ch/shop')" link title="Shop"></v-list-item>
        <v-list-item
            @click="openLinkNewWindow('https://app.wellcomefit.ch/contract')"
            link
            title="Mitglied werden"
        ></v-list-item>
        <v-list-item @click="freeWorkout" link title="Kostenlos Trainieren"></v-list-item>
        <v-list-item @click="openLink('/about')" link title="Über uns"></v-list-item>

        <v-list-item v-if="false" link title="Partner"></v-list-item>
        <v-list-item @click="openLink('/jobs')" link title="Jobs"></v-list-item>
        <v-list-item @click="openLink('/sportwoche')" link>
            <v-list-item-title>
                Sportwoche 2025
                <v-icon class="ml-2" small>mdi-palm-tree</v-icon>
            </v-list-item-title>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: 'NavigationDrawer',
        props: ['drawer'],
        methods: {
            freeWorkout() {
                let elevenDaysSites = ['home', 'clubPageView']
                if (elevenDaysSites.includes(this.$route.name)) {
                    this.$router.push({ query: { ...this.$route.query, 'gratis-training': true } })
                } else {
                    // Query-Parameter extrahieren und `gratis-training` hinzufügen
                    const updatedQuery = { ...this.$route.query, 'gratis-training': true }
                    // Zur Startseite navigieren und die Query-Parameter anhängen
                    this.$router.push({ path: '/', query: updatedQuery })
                }
            },
            scrollToTop() {
                this.$emit('scrollTop')
            },
            openLink(link) {
                this.$router.push(link)
                this.scrollToTop()
            },
            openLinkNewWindow(link) {
                window.open(link, '_blank')
            },
        },
    }
</script>
