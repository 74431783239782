<template>
    <v-container style="margin-top: 64px;" fluid class="formular-container px-0" >
        <v-card  class="mx-auto" variant="flat">
            <v-row class="formular" v-if="form" no-gutters>
                <!-- Bild --> 
                <v-col v-if="form.image" cols="12" md="8" class="d-flex align-center justify-center flex-column">
                    <img :src="'https://app.wellcomefit.ch/img' + form.image" width="100%"></img>
                    <!-- Beschreibung -->
                    <v-card-text v-if="form.description"  v-html="form.description"></v-card-text>
                </v-col>
                <!-- Text und Formular -->
                <v-col cols="12" md="4" class="px-5">
                    <v-img class="hide-mobile" :src="logo" height="120" contain></v-img>
                    <div>
                        <!-- Einführungstext -->
                        <v-card-text v-if="form.leadFields.active" class="mb-5">
                            Lass uns doch wissen wer du bist, damit wir dich umgehend kontaktieren und einen ersten
                            Termin vereinbaren können.
                            <br />
                            <br />
                            Die
                            <span style="color: #00963f; cursor: pointer" @click="$router.push('/agb')">AGB</span>
                            und
                            <span style="color: #00963f; cursor: pointer" @click="$router.push('/datenschutz')">
                                Datenschutzrichtlinie
                            </span>
                            gibt es
                            <span @click="$router.push('/agb')">hier.</span>
                        </v-card-text>

                        <!-- Formular -->
                        <v-text-field
                            class="mb-3"
                            hide-details
                            v-if="form.leadFields.name"
                            label="Vorname"
                            v-model="info.name"
                        ></v-text-field>
                        <v-text-field
                            class="mb-3"
                            hide-details
                            v-if="form.leadFields.surname"
                            label="Name"
                            v-model="info.surname"
                        ></v-text-field>
                        <v-text-field
                            class="mb-3"
                            hide-details
                            v-if="form.leadFields.phone"
                            label="Telefonnummer"
                            v-model="info.phone"
                        ></v-text-field>
                        <v-text-field
                            hide-details
                            class="mb-3"
                            v-if="form.leadFields.email"
                            label="E-Mail"
                            v-model="info.email"
                        ></v-text-field>
                        <v-textarea
                            class="mb-5"
                            hide-details
                            v-if="form.leadFields.message"
                            label="Nachricht"
                            v-model="info.message"
                        ></v-textarea>

                        <!-- Studio-Suche -->
                        <v-card-subtitle v-if="!info.studio" class="mb-2 font-weight-bold">
                            Finde Dein Studio
                        </v-card-subtitle>
                        <v-autocomplete
                            v-if="!info.studio"
                            :items="filteredStudios"
                            autocomplete="nope"
                            item-title="name"
                            item-value="code"
                            append-inner-icon="mdi-magnify"
                            placeholder="Suche nach Kanton, Stadt oder PLZ"
                            outlined
                            class="custom-autocomplete mb-5"
                            hide-details
                            style="background-color: white; border-radius: 4px; flex-grow: 1"
                            no-filter
                            @input="onInput"
                        >
                            <template v-slot:item="{ item, index }">
                                <v-list-item
                                    @click="info.studio = getStudioInfosByCode(item.value)._id"
                                    :class="{ 'border-bottom-except-last': index + 1 !== filteredStudios.length }"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ getStudioInfosByCode(item.value).name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mb-1">
                                            {{ getStudioInfosByCode(item.value).adresse.split(',')[0] }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="mb-1">
                                            {{ getStudioInfosByCode(item.value).adresse.split(',')[1] }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                        <div v-else class="d-flex justify-center flex-column mb-5">
                            <h3 class="text-center mt-2">
                                {{ getStudioInfosById(info.studio).name }}
                            </h3>
                            <p class="text-center">{{ getStudioInfosById(info.studio).adresse }}</p>
                            <p @click="openLink('tel:' + getStudioInfosById(info.studio).telefon)" class="text-center">
                                <v-icon color="primary">mdi-phone</v-icon>
                                {{ getStudioInfosById(info.studio).telefon }}
                            </p>
                            <p @click="openLink('mailto:' + getStudioInfosById(info.studio).email)" class="text-center">
                                <v-icon color="primary">mdi-email</v-icon>
                                {{ getStudioInfosById(info.studio).email }}
                            </p>
                            <v-btn class="my-2 mx-auto" variant="text" @click="resetStudioSearch">
                                Studio wechseln
                            </v-btn>
                        </div>
                    </div>
                    <v-alert class="mb-5" v-if="errors.length" type="info" dismissible>
                        <span v-for="(error, index) in errors" :key="index">
                            {{ error }}
                            <br />
                        </span>
                    </v-alert>
                    <v-btn class="mb-5" @click="submit" size="large" color="primary">Jetzt Absenden</v-btn>
                </v-col>
            </v-row>

            <!-- Loading -->
            <v-row v-else-if="loading">
                <v-col cols="12" class="d-flex justify-center align-center" style="min-height: calc(100vh - 600px)">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>

            <!-- Seite nicht gefunden -->
            <v-row v-else>
                <v-col class="mt-5" style="min-height: calc(100vh - 600px)" cols="12">
                    <v-alert type="info">Seite nicht gefunden</v-alert>
                    <v-btn color="primary" class="mt-5" @click="$router.push('/')">Zur Startseite</v-btn>
                </v-col>
            </v-row>

            <!-- Danke Dialog -->
            <v-dialog persistent max-width="500" v-model="showDankeDialog">
                <v-card>
                    <v-card-title style="background-color: #00963f; color: white">
                        Vielen Dank für deine Nachricht!
                    </v-card-title>
                    <v-card-text class="mt-5">
                        Wir werden uns umgehend bei dir melden, um einen Starttermin festzulegen.
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn @click="$router.push('/')" color="primary">Zur Startseite</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
    import logo from '@/assets/groupLogoWhiteBG.jpg'
    export default {
        data() {
            return {
                logo,
                loading: true,
                showDankeDialog: false,
                form: null,
                info: {
                    studio: null,
                    name: null,
                    surname: null,
                    phone: null,
                    email: null,
                    message: null,
                },
                errors: [],
                search: '',
                filteredStudios: [],
                inputTimeout: null,
            }
        },
        created() {
            let adLeadForms = {
                'leadViewGoogle': '11-days-google',
                'leadViewMeta': '11-days-meta',
            }

            this.link = adLeadForms[this.$route.name] || this.$route.params.link
            this.init()
        },
        methods: {
            openLink(link) {
                window.open(link)
            },
            resetStudioSearch() {
                this.search = ''
                this.filterStudios()
                this.info.studio = null
            },
            getStudioInfosById(id) {
                let studioInfos = {
                    _id: '',
                    name: '',
                    adresse: '',
                    telefon: '',
                    email: '',
                }
                let studio = this.form.studios.find((e) => e._id === id)
                if (!studio) return studioInfos
                return {
                    name: studio.name,
                    adresse: studio.adresse,
                    telefon: studio.telefon,
                    email: studio.email,
                    _id: studio._id,
                }
            },
            getStudioInfosByCode(code) {
                let studioInfos = {
                    _id: '',
                    name: '',
                    adresse: '',
                }
                let studio = this.form.studios.find((e) => e.code === code)
                if (!studio) return studioInfos
                return { name: studio.name, adresse: studio.adresse, _id: studio._id }
            },
            async submit() {
                // Fehlerliste zurücksetzen
                this.errors = []

                // Pflichtfeldprüfung für Vor- und Nachname
                if (!this.info.name || !this.info.name.trim()) {
                    this.errors.push('Vorname ist erforderlich.')
                }
                if (!this.info.surname || !this.info.surname.trim()) {
                    this.errors.push('Nachname ist erforderlich.')
                }

                // Prüfung, ob mindestens Telefon oder E-Mail ausgefüllt ist
                if ((!this.info.phone || !this.info.phone.trim()) && (!this.info.email || !this.info.email.trim())) {
                    this.errors.push('Bitte gib entweder eine Telefonnummer oder eine E-Mail-Adresse an.')
                }

                // E-Mail-Validierung, falls E-Mail angegeben wurde
                if (this.info.email && this.info.email.trim()) {
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    if (!emailPattern.test(this.info.email)) {
                        this.errors.push('Bitte gib eine gültige E-Mail-Adresse ein.')
                    }
                }

                // Telefonnummer-Validierung, falls Telefonnummer angegeben wurde
                if (this.info.phone && this.info.phone.trim()) {
                    const phonePattern = /^[0-9+\s()-]+$/
                    if (!phonePattern.test(this.info.phone)) {
                        this.errors.push('Bitte gib eine gültige Telefonnummer ein.')
                    }
                }

                // Prüfung, ob ein Studio ausgewählt wurde
                if (!this.info.studio) {
                    this.errors.push('Bitte wähle ein Studio aus.')
                }

                // Wenn Fehler vorhanden sind, Abbruch der Funktion
                if (this.errors.length > 0) {
                    return
                }

                // Daten senden, wenn alle Validierungen bestanden sind
                try {
                    let leadEntry = {
                        studio: this.info.studio,
                        customer: '5ffc504f26abe65228e36a21',
                        leadSetting: this.form._id,
                        platform: '',
                        name: this.info.name,
                        surname: this.info.surname,
                        phone: this.info.phone,
                        email: this.info.email,
                        message: this.info.message,
                        status: 'Wurde angeworben',
                    }

                  let response = await this.$axios.post('https://app.wellcomefit.ch/api/websitelead', { leadEntry })
                   
                    if (response.status === 200) {
                        // Erfolgreiche Übermittlung behandeln
                        this.$router.push({
    name: 'thankyou',
    query: {
        name: this.info.name,
        surname: this.info.surname,
        email: this.info.email,
        phone: this.info.phone,
        message: this.info.message,
        studio: this.getStudioInfosById(this.info.studio).name,
    },
})

                    } else {
                        throw new Error('Fehler beim Senden des Formulars.')
                    }
                } catch (error) {
                    console.error(error)
                    this.errors.push('Es gab ein Problem bei der Übermittlung. Bitte versuche es später erneut.')
                }
            },
            async init() {
                this.loading = true
                this.form = null
                try {
                    let leadForm = await this.$axios.get('https://app.wellcomefit.ch/api/websitelead/form/' + this.link)

                    if (leadForm.status === 200 && leadForm.data) {
                        this.form = leadForm.data
                        // Studios initialisieren
                        this.filteredStudios = this.form.studios
                        console.log(this.form.studios)
                    } else {
                        throw Error('Fehler beim Laden')
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            },
            onInput(val) {
                clearTimeout(this.inputTimeout)
                this.inputTimeout = setTimeout(() => {
                    this.search = val.target.value
                    this.filterStudios()
                }, 300)
            },
            filterStudios() {
                if (!this.search) {
                    this.filteredStudios = this.form.studios
                    return
                }
                const searchLower = this.search.toLowerCase()
                this.filteredStudios = this.form.studios.filter((studio) => {
                    return (
                        (studio.canton && studio.canton.toLowerCase().includes(searchLower)) ||
                        (studio.art && studio.art.toLowerCase().includes(searchLower)) ||
                        (studio.name && studio.name.toLowerCase().includes(searchLower)) ||
                        (studio.adresse && studio.adresse.toLowerCase().includes(searchLower)) ||
                        (studio.email && studio.email.toLowerCase().includes(searchLower)) ||
                        (studio.code && studio.code.toLowerCase().includes(searchLower))
                    )
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.hide-mobile {
    @media only screen and (max-width: 800px) {
           display: none;
        }
}
.formular-container {
    @media only screen and (min-width: 1000px) {
            max-width: 80%;
        }
}
.formular {
    @media only screen and (min-width: 1000px) {
            margin: 5vh 0;
        }
}
    ::v-deep .mdi-menu-down {
        display: none;
    }

    .custom-autocomplete .v-input__control {
        min-height: 0px;
    }

    .border-bottom-except-last {
        border-bottom: 1px solid lightgrey;
    }
    /* Benutzerdefinierter Stil für Aufzählungen */
    ::v-deep ul {
        list-style-type: none;
        padding-left: 1.5em; /* Platz für das Emoji */
    }

    ::v-deep ul li:before {
        content: '◾'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
        position: absolute;
        margin-left: -1.5em; /* Positionierung des Emojis */
    }

    ::v-deep ol {
        list-style-type: none;
        padding-left: 1.5em; /* Platz für das Emoji */
    }

    ::v-deep ol li:before {
        content: '👉'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
        position: absolute;
        margin-left: -1.5em; /* Positionierung des Emojis */
    }
    ::v-deep .no-uppercase {
        text-transform: unset !important;
        letter-spacing: normal !important;
    }
</style>
