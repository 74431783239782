<template>
    <v-container class="pa-0" fluid style="margin-top: 64px">
        <router-view></router-view>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {}
        },
    }
</script>
