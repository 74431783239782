<template>
    <v-container fluid class="footer pt-0 d-flex flex-column">
        <v-btn
            @click="scrollToTop"
            style="font-size: 13px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px"
            variant="tonal"
            height="60px"
            class="mx-auto text-white"
        >
            <v-icon>mdi-arrow-up</v-icon>
            nach Oben
        </v-btn>

        <div class="d-flex flex-column text-center py-5 text-white">
            <v-list-item class="upper-section" @click="openLink('/clubs')" link title="Club Auswahl"></v-list-item>
            <v-list-item
                class="upper-section"
                @click="openLinkNewWindow('https://app.wellcomefit.ch/contract')"
                link
                title="Mitglied werden"
            ></v-list-item>
            <v-list-item @click="openLink('/about')" class="upper-section" link title="Über uns"></v-list-item>
            <v-list-item
                @click="openLink('/sportwoche')"
                class="upper-section"
                link
                title="Sportwoche 2025"
            ></v-list-item>
            <v-list-item v-if="false" class="upper-section" link title="Partner"></v-list-item>
            <v-list-item @click="openLink('/jobs')" class="upper-section" link title="Jobs"></v-list-item>
            <v-list-item class="upper-section" @click="openLink('/kontakt')" link title="Kontakt"></v-list-item>
        </div>
        <v-divider color="white"></v-divider>
        <div class="d-flex flex-column text-center py-5 text-white">
            <v-list-item @click="openLink('/impressum')" class="lower-section" link title="Impressum"></v-list-item>
            <v-list-item @click="openLink('/datenschutz')" class="lower-section" link title="Datenschutz"></v-list-item>
            <v-list-item @click="openLink('/agb')" class="lower-section" link title="AGB"></v-list-item>
        </div>
        <div style="max-width: 400px" class="d-flex mx-auto">
            <v-row>
                <v-col cols="4">
                    <v-img
                        class="cursor-pointer"
                        width="50px"
                        @click="openLinkNewWindow('https://www.facebook.com/wellcomefitag/')"
                        :src="facebook"
                    ></v-img>
                </v-col>
                <v-col cols="4">
                    <v-img
                        class="cursor-pointer"
                        width="50px"
                        @click="openLinkNewWindow('https://www.instagram.com/wellcomefit')"
                        :src="instagram"
                    ></v-img>
                </v-col>
                <v-col cols="4">
                    <v-img
                        class="cursor-pointer"
                        width="50px"
                        @click="openLinkNewWindow('https://www.tiktok.com/@wellcomefit')"
                        :src="tiktok"
                    ></v-img>
                </v-col>
            </v-row>
        </div>
        <v-card-text class="mt-3 text-center text-white">© {{ currentYear }} All rights reserved.</v-card-text>
    </v-container>
</template>
<script>
    import facebook from '@/assets/facebook.png'
    import instagram from '@/assets/instagram.png'
    import tiktok from '@/assets/tiktok.png'
    export default {
        data() {
            return {
                facebook,
                instagram,
                tiktok,
            }
        },
        methods: {
            scrollToTop() {
                this.$emit('scrollTop')
            },
            openLink(link) {
                this.$router.push(link)
                this.scrollToTop()
            },
            openLinkNewWindow(link) {
                window.open(link, '_blank')
            },
        },
        computed: {
            currentYear() {
                return new Date().getFullYear()
            },
        },
    }
</script>

<style>
    .cursor-pointer {
        cursor: pointer;
    }
    .footer {
        background-color: #313131;
    }
    .upper-section .v-list-item-title {
        font-size: 20px;
    }
    .v-list-item__overlay {
        opacity: 0 !important;
    }
    .lower-section .v-list-item-title {
        font-size: 14px;
    }
</style>
