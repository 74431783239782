<template>
    <v-container>
        <v-row v-if="$vuetify.display.mdAndUp">
            <!-- Standardzimmer 1 Person -->
            <v-col cols="12" md="6">
                <v-card class="pa-4">
                    <v-card-title class="headline text-center">
                        Standardzimmer
                        <br />
                        1 Person
                    </v-card-title>
                    <v-card-subtitle class="display-2 text-center font-weight-bold">CHF 1'477.-</v-card-subtitle>
                    <p class="text-center">zzgl. Flug</p>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">7 Übernachtungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">211 CHF pro Tag</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="openAlibeyInRoody" block color="blue" class="mt-4">Buchen</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <p class="text-center">
                        26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage,
                        Safe, Dusche / WC, Föhn, Keramikboden, Möblierter Balkon, Ultra All inclusive.
                    </p>
                </v-card>
            </v-col>

            <!-- Standardzimmer 2 Personen -->
            <v-col cols="12" md="6">
                <v-card class="pa-4">
                    <v-card-title class="headline text-center">
                        Standardzimmer
                        <br />
                        2 Personen
                    </v-card-title>
                    <v-card-subtitle class="display-2 text-center font-weight-bold">CHF 2'044.-</v-card-subtitle>
                    <p class="text-center">zzgl. Flug</p>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">7 Übernachtungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">292 CHF pro Tag</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="openAlibeyInRoody" block color="blue" class="mt-4">Buchen</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <p class="text-center">
                        26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage,
                        Safe, Dusche / WC, Föhn, Keramikboden, Möblierter Balkon, Ultra All inclusive.
                    </p>
                </v-card>
            </v-col>

            <!-- Superior Standardzimmer 1 Person -->
            <v-col cols="12" md="6">
                <v-card class="pa-4">
                    <v-badge color="green" class="mb-2" content="Superior" overlap>
                        <v-card-title class="headline text-center">
                            Superior Standardzimmer
                            <br />
                            1 Person
                        </v-card-title>
                    </v-badge>
                    <v-card-subtitle class="display-2 text-center font-weight-bold">CHF 1'631.-</v-card-subtitle>
                    <p class="text-center">zzgl. Flug</p>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">7 Übernachtungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">233 CHF pro Tag</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="openAlibeyInRoody" block color="blue" class="mt-4">Buchen</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <p class="text-center">
                        26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage,
                        Safe, Dusche / WC, Föhn, Marmorfußboden, Möblierter Balkon, Ultra All inclusive.
                    </p>
                </v-card>
            </v-col>

            <!-- Superior Standardzimmer 2 Personen -->
            <v-col cols="12" md="6">
                <v-card class="pa-4">
                    <v-badge color="green" class="mb-2" content="Superior" overlap>
                        <v-card-title class="headline text-center">
                            Superior Standardzimmer
                            <br />
                            2 Personen
                        </v-card-title>
                    </v-badge>
                    <v-card-subtitle class="display-2 text-center font-weight-bold">CHF 2'240.-</v-card-subtitle>
                    <p class="text-center">zzgl. Flug</p>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">7 Übernachtungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">320 CHF pro Tag</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="openAlibeyInRoody" block color="blue" class="mt-4">Buchen</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <p class="text-center">
                        26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage,
                        Safe, Dusche / WC, Föhn, Marmorfußboden, Möblierter Balkon, Ultra All inclusive.
                    </p>
                </v-card>
            </v-col>
        </v-row>

        <!-- Mobile Ansicht mit Carousel -->
        <v-carousel v-if="$vuetify.display.smAndDown" hide-delimiters>
            <v-carousel-item v-for="(room, index) in rooms" :key="index">
                <v-card class="pa-4">
                    <v-card-title class="headline text-center">
                        {{ room.title }}
                        <br />
                        {{ room.persons }}
                        <span v-if="parseInt(room.persons) > 1">Personen</span>
                        <span v-else>Person</span>
                    </v-card-title>
                    <v-card-subtitle class="display-2 text-center font-weight-bold">
                        CHF {{ room.price }}.-
                    </v-card-subtitle>
                    <p class="text-center">zzgl. Flug</p>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">7 Übernachtungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">
                                    {{ room.pricePerDay }} CHF pro Tag
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="openAlibeyInRoody" block color="blue" class="mt-7">Buchen</v-btn>
                    <v-divider class="my-4"></v-divider>
                    <p class="text-center">{{ room.description }}</p>
                </v-card>
            </v-carousel-item>
        </v-carousel>
    </v-container>
</template>

<script>
    export default {
        name: 'RoomPricing',
        data() {
            return {
                rooms: [
                    {
                        title: 'Standardzimmer',
                        persons: 1,
                        price: "1'477",
                        pricePerDay: 211,
                        description:
                            '26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage, Safe, Dusche / WC, Föhn, Keramikboden, Möblierter Balkon, Ultra All inclusive.',
                    },
                    {
                        title: 'Standardzimmer',
                        persons: 2,
                        price: "2'044",
                        pricePerDay: 292,
                        description:
                            '26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage, Safe, Dusche / WC, Föhn, Keramikboden, Möblierter Balkon, Ultra All inclusive.',
                    },
                    {
                        title: 'Superior Standardzimmer',
                        persons: 1,
                        price: "1'631",
                        pricePerDay: 233,
                        description:
                            '26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage, Safe, Dusche / WC, Föhn, Marmorfußboden, Möblierter Balkon, Ultra All inclusive.',
                    },
                    {
                        title: 'Superior Standardzimmer',
                        persons: 2,
                        price: "2'240",
                        pricePerDay: 320,
                        description:
                            '26 m2, Kabelloses Internet (Wi-Fi), SAT LCD TV, Musikkänale, Telefon, Minibar, Klimaanlage, Safe, Dusche / WC, Föhn, Marmorfußboden, Möblierter Balkon, Ultra All inclusive.',
                    },
                ],
            }
        },
        methods: {
            openAlibeyInRoody() {
                window.open('https://app.wellcomefit.ch/alibey', '__blank')
            },
        },
    }
</script>

<style scoped>
    .text-center {
        text-align: center;
    }
    .font-weight-bold {
        font-weight: bold;
    }
</style>
