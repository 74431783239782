<template>
    <div class="header-content" style="margin-top: 64px">
        <!-- Placeholder div -->
        <div v-if="!videoLoaded" class="loading-video">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
                class="video-loader mx-auto"
            ></v-progress-circular>
        </div>

        <!-- Video element -->
        <video
            :class="{ loading: !videoLoaded }"
            :src="getDynamicVideoSrc(placeholder)"
            autoplay
            muted
            loop
            playsinline
            class="header-video"
            @loadeddata="onVideoLoaded"
        ></video>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                placeholder: 'video-1723190254585.mp4',
                url: 'https://app.wellcomefit.ch',
                videoLoaded: false,
            }
        },
        methods: {
            onVideoLoaded() {
                this.videoLoaded = true // Set to true when the video has loaded
            },
            getVideoSrc(vid, size) {
                return `${vid.replace('.mp4', '')}-${size}.mp4`
            },
            getDynamicVideoSrc(vid) {
                const width = window.innerWidth
                if (width >= 1024) {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'original')
                } else if (width >= 640) {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'large')
                } else {
                    return this.url + '/api/website/video/' + this.getVideoSrc(vid, 'small')
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .loading-video {
        position: relative;
        text-align: center;
        color: white;
        border-bottom: 5px solid #00963f;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        aspect-ratio: 16 / 9; // Stellt das Seitenverhältnis auf 16:9 ein
        width: 100%; // Nimmt die gesamte Breite des Containers ein
        @media only screen and (min-width: 1000px) {
            height: 70vh !important;
        }
    }
    .header-content {
        position: relative;
        aspect-ratio: 16 / 9; /* Sets the aspect ratio to 16:9 */
        width: 100%;
        border-bottom: 5px solid #00963f;
        max-height: 400px;
        @media only screen and (min-width: 1000px) {
            max-height: 70vh !important;
        }
    }

    .header-placeholder {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .header-video {
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; /* Ensures the video covers the entire container */
    }
    .header-video.loading {
        width: 0% !important;
        height: 0% !important;
    }
</style>
