<template>
    <div class="home">
        <Header />

        <ClubSearchBox style="margin-top: -50px; z-index: 1" />
        <FitnessSection class="mt-5" />

        <ElevenDays />

        <GroupFitnessSection />
        <CoursePlan />

        <div style="background-color: #f5fcff">
            <WellnessSection />
        </div>
    </div>
</template>

<script>
    import Header from '@/components/home/Header'
    import ClubSearchBox from '@/components/ClubSearchBox'
    import ElevenDays from '@/components/offers/ElevenDays'
    import FitnessSection from '@/components/home/FitnessSection'
    import GroupFitnessSection from '@/components/home/GroupFitnessSection'
    import CoursePlan from '@/components/groupFitness/CoursePlan'
    import WellnessSection from '@/components/home/WellnessSection'

    export default {
        name: 'HomeView',
        beforeRouteEnter(to, from, next) {
            document.title = 'well come FIT group | 11 Tage Gratis Training – Starte Jetzt'
            const metaDescription = document.querySelector('meta[name="description"]')
            if (metaDescription) {
                metaDescription.setAttribute(
                    'content',
                    'Dein Partner für Fitness und Wellness mit individuellem Personal Training und top-ausgebildeten Instruktoren.'
                )
            }
            next()
        },
        components: {
            Header,
            ClubSearchBox,
            ElevenDays,
            FitnessSection,
            GroupFitnessSection,
            WellnessSection,
            CoursePlan,
        },
    }
</script>
<style></style>
