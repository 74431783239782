<template>
    <v-card max-width="1000px" class="mx-auto text-left" style="margin-top: 64px" variant="flat">
        <v-card-title class="mt-5">Allgemeine Geschäftsbedingungen</v-card-title>
        <v-card-subtitle>Mitgliedschaftsbedingungen</v-card-subtitle>
        <v-card-text class="mb-5">
            1. Die Einrichtungen stehen jedem Mitglied, gemäss diesem Vertrag, während den Öffnungszeiten zur Verfügung.
            Die Konsumation von Getränken (nicht Offenausschank) und Nahrungsmitteln, sowie die Teilnahme an
            Spezialveranstaltungen, sind im Preis nicht inbegriffen. Die Gebühren sind aus der aktuellen Preisliste
            ersichtlich. Das Nichtbenützen der Einrichtungen und Leistungen berechtigt nicht zur Reduktion oder
            Rückforderung der Beiträge. Der Groupfitness Plan kann jederzeit geändert werden. Zudem besteht kein
            Anspruch auf einen bestimmten Instruktor oder Instruktorin.
            <br />
            <br />
            2. Dieser Vertrag verlängert sich automatisch um jeweils 12 Monate, wenn dieser nicht 45 Tage vor
            Vertragsablauf schriftlich gekündigt wird. Massgebend für das Einhalten der Frist ist das Datum des
            Poststempels. Der Mitgliedschaftspreis für den verlängerten Vertrag richtet sich nach der, 60 Tage vor
            Verlängerungsbeginn, geltenden Mitgliedschaftspreisliste (die Preisliste liegt immer in jedem Center der
            well come FIT zur Einsicht auf).
            <br />
            <br />
            3. Der Kaufpreis für den Mitgliedsausweis / Chip beträgt CHF 50.00.-. Dieser ist persönlich und nicht
            übertragbar. Jeder Verlust des Mitgliedsausweises / Chip ist der well come Fit AG sofort zu melden. Bei
            Verlust des Mitgliedsausweises/Chips hat das Mitglied einen Wiederbeschaffungsbeitrag von CHF 50.00.- zu
            leisten. Der Mitgliedsausweis/Chip ist vor und nach jedem Besuch, vorzuweisen. Das Mitglied haftet gegenüber
            der well come FIT AG für Schäden, aufgrund Missbrauchs des Mitgliederausweises / Chip, soweit das Mitglied
            den Missbrauch vorsätzlich oder fahrlässig ermöglicht hat. Missbrauch hat den sofortigen Verlust der
            Mitgliedschaft ohne Anspruch auf Rückerstattung des Mitgliedsbeitrages, zur Folge. Die Rückgabe des
            Mitgliedsausweises/Chips ersetzt nicht die schriftliche Kündigung des Vertrages.
            <br />
            <br />
            4. Im Falle von zusätzlichen Schliessungen, zum Beispiel infolge Reinigung oder Revision und für
            Betriebsunterbrüche infolge höherer Gewalt (Epidemien / Pandemien) und anderer unvorhersehbarer Ereignissee,
            besteht für das Mitglied keinerlei Anspruch auf Rückvergütungen / Timestops. etc. .
            <br />
            <br />
            5. Bei einem Rückstand des Mitgliedsbeitrages, auf das vereinbarte Zahlungsziel, wird ein Verzugszins von 5
            % p.a. fällig. Erfolgt die Begleichung des Mitgliederbeitrages in Ratenzahlungen, wird im Falle des Verzuges
            von zwei Raten, sofort der ganze Restbetrag fällig. Für Umtriebe werden ab der 2. Mahnung und andere auf
            Verzugsfolgen zurückgehende Briefe, dem Mitglied je CHF 20.- in Rechnung gestellt.
            <br />
            <br />
            6. Mit Ausnahme der Betriebshaftpflicht haften wir nicht für Unfälle und Wertgegenstände. Der Abschluss
            einer Unfall und / oder Diebstahlversicherung ist Sache des Mitglieds. Während den Öffnungszeiten ist das
            Center immer überwacht.
            <br />
            <br />
            7. Den Anweisungen des Personals ist Folge zu leisten. Die Hygienevorschriften sind strikt zu beachten.
            Grobe oder wiederholte Verstösse haben den Entzug der Mitgliedschaft, ohne Anspruch auf Rückerstattung zur
            Folge.
            <br />
            <br />
            8. Mit einer Genehmigung der Geschäftsleitung der well come FIT AG kann die Mitgliedschaft nur bei der
            Kombi-Card unterbrochen werden. Der Unterbruch muss mind. einen Monat betragen und darf 6 Monate nicht
            überschreiten. Dem Antrag sind Unterlagen zur Prüfung beizulegen. Ein Unterbruch befreit nicht von der
            Zahlungspflicht und wird nach Bezahlung der Unterbruchsgebühr der aktuellen Laufzeit angerechnet. Die Gebühr
            beträgt beim Jahresabonnement 1/24 und beim ½-Jahresabonnement 1/12 des Mitgliederbeitrages pro Monat
            Unterbruch.
            <br />
            <br />
            9. Mit einer Genehmigung der Geschäftsleitung der well come FIT AG, kann der Mitgliedsvertrag einem
            Nicht-Mitglied übertragen werden.
            <br />
            <br />
            10. Der Mitgliedsvertrag kann nicht von einem Ehemaligen-Mitglied oder einem bestehenden Mitglied übernommen
            werden. Die Übernahmegebühr beträgt mindestens CHF 20.- plus die Einschreibegebühr von CHF 99.-.
            <br />
            <br />
            11. Beim Umgang mit Daten hält sich well come FIT an die geltende Gesetzgebung, insbesondere an das
            Datenschutzrecht. well come FIT erhebt, speichert und bearbeitet nur Daten, die für die Erbringung der
            Dienstleistungen, für die Abwicklung und Pflege der Kundenbeziehungen, namentlich die Gewährleistung einer
            hohen Dienstleistungsqualität, für die Sicherheit von Betrieb und Infrastruktur, sowie für die
            Rechnungsstellung benötigt werden. Der Kunde willigt ein, dass well come FIT im Zusammenhang mit Abschluss
            und Abwicklung des Vertrages Auskünfte über ihn einholen bzw. Daten betreffend seinem Zahlverhalten
            weitergeben kann; seine Daten zu Inkassozwecken an Dritte weitergeben darf; seine Daten für Marketingzwecke
            bearbeiten darf, namentlich für die bedarfsgerechte Gestaltung und Entwicklung ihrer Dienstleistungen. Der
            Kunde kann die Verwendung seiner Daten zu Marketingzwecken einschränken oder untersagen lassen.
            <br />
            <br />
            12. Die well come FIT AG überwacht seine Fitnessstudios mit Videokameras und speichert die Aufnahmen, soweit
            und solange dies im Einzelfall zur Sicherheit seiner Mitglieder und Aufklärung von Straftaten erforderlich
            ist. Der Umstand der Beobachtung und die verantwortliche Stelle werden durch Hinweisschilder erkennbar
            gemacht. Die einschlägigen Bestimmungen werden eingehalten.
            <br />
            <br />
            13. Sollte eine oder mehrere Bestimmungen dieses Vertrages unwirksam sein, so lässt dies die Wirksamkeit des
            Vertrages, sowie der übrigen Bestimmungen unberührt.
            <br />
            <br />
            14. Für alle aus diesem Vertrag entstehenden Streitigkeiten gilt als ausschliesslicher Gerichtsstand der
            Hauptsitz der well come FIT AG in Frauenfel.
        </v-card-text>
    </v-card>
</template>
