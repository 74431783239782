<template>
    <v-card flat color="transparent" class="mt-5">
        <v-card-title class="text-h5 pb-3">Mitgliedschaften</v-card-title>
        <v-row>
            <v-col v-for="(contract, index) of contracts" cols="12" :md="4">
                <v-card
                    :variant="index === 0 ? 'elevated' : 'outlined'"
                    width="90%"
                    :color="index === 0 ? 'primary' : ''"
                    style="border-color: lightgray"
                    class="rounded-lg mx-auto py-4"
                >
                    <v-card-title class="font-weight-bold text-center pb-0">{{ contract.title }}</v-card-title>
                    <v-card-subtitle class="text-center pt-0">{{ fetchSubtitleByContract(contract) }}</v-card-subtitle>
                    <div class="text-center pt-5">
                        <span class="ab-class">ab</span>
                        <span class="price-class">{{ contract.price }}.-</span>
                        <span class="time-class">/ Jahr</span>
                    </div>

                    <v-row>
                        <v-col class="d-flex justify-center my-2" cols="12">
                            <v-btn
                                variant="outlined"
                                style="border-width: 2px"
                                :color="index !== 0 ? 'primary' : ''"
                                @click="openAboShop"
                            >
                                Abo lösen
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-expansion-panels flat class="mb-7">
                        <v-expansion-panel
                            :key="index"
                            :bg-color="index === 0 ? 'primary' : ''"
                            tile
                            style="border-radius: 0px"
                        >
                            <v-expansion-panel-title
                                style="max-width: 230px"
                                class="no-border-radius mx-auto remove-hover"
                            >
                                Weitere Informationen
                            </v-expansion-panel-title>
                            <v-expansion-panel-text
                                :style="index === 0 ? 'background-color: #00963f;color: white;' : ''"
                            >
                                <v-row no-gutters v-if="contract.packages && contract.packages.length > 0">
                                    <v-col cols="12">
                                        <v-card-title class="text-h6 text-center pb-2">Zusatzpakete</v-card-title>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card-text v-for="p of contract.packages" class="text-center py-1">
                                            {{ p.title }}
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-card-text class="text-center">
                                            {{ fetchTextByContract(contract) }}
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
        <div style="width: 90%" class="d-flex justify-end mx-auto mt-5">
            <v-btn @click="openAboShop" variant="text" size="large" color="primary">
                <v-icon class="pr-2">mdi-arrow-right</v-icon>
                Alle Abos ansehen
            </v-btn>
        </div>
    </v-card>
</template>
<script>
    export default {
        props: {
            studio: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                contracts: [],
                contractText: {
                    'Kombi Card': {
                        subtitle: 'Fitness und Groupfitness',
                        text: 'Mit der Kombi Card trainierst du an den Geräten und kannst alle Groupfitness Kurse besuchen.',
                    },
                    PREMIUM: {
                        subtitle: 'Fitness, Groupfitness und Wellness',
                        text: 'Mit unserem Premium Abonnement kommst du in den Genuss sämtlicher Zusatzpakete an allen Standorten des well come FIT (exkl. den Wellness SPA-Bereichen mit Pool, wie am Standort St. Gallen Einstein). Mit dem Premium plus Abonnement stehen dir neben der Nutzung sämtlicher Zusatzpakete an allen Standorten auch der Zugang zu unseren Wellness-Bereichen inkl. Pool (wie z.Bsp. im well come FIT Einstein) zur Verfügung.',
                    },
                    'PREMIUM plus': {
                        subtitle: 'Fitness, Groupfitness und Wellness',
                        text: 'Mit unserem Premium Abonnement kommst du in den Genuss sämtlicher Zusatzpakete an allen Standorten des well come FIT (exkl. den Wellness SPA-Bereichen mit Pool, wie am Standort St. Gallen Einstein). Mit dem Premium plus Abonnement stehen dir neben der Nutzung sämtlicher Zusatzpakete an allen Standorten auch der Zugang zu unseren Wellness-Bereichen inkl. Pool (wie z.Bsp. im well come FIT Einstein) zur Verfügung.',
                    },
                    'Group Fitness': {
                        subtitle: 'Groupfitness',
                        text: 'Das Groupfitness Abonnement berechtigt dich zum Besuch sämtlicher Groupfitness Kurse.',
                    },
                    'Erwachsene ab 18 Jahren': {
                        subtitle: 'Fitness und Groupfitness',
                        text: 'Mit dem Erwachsene ab 18 Jahren Abo trainierst du an den Geräten und kannst alle Groupfitness Kurse besuchen.',
                    },
                    'AHV/Studenten/Lernende': {
                        subtitle: 'Fitness und Groupfitness',
                        text: 'Mit dem AHV/Studenten/Lernende Abo trainierst du an den Geräten und kannst alle Groupfitness Kurse besuchen.',
                    },
                },
            }
        },
        created() {
            this.fetchContracts()
        },
        methods: {
            fetchTextByContract(contract) {
                return this.contractText[contract.title]?.text || ''
            },
            fetchSubtitleByContract(contract) {
                return this.contractText[contract.title]?.subtitle || ''
            },
            openAboShop() {
                window.open('https://app.wellcomefit.ch/contract?studio=' + this.studio.code, '_blank')
            },
            async fetchContracts() {
                try {
                    let contracts = await this.$axios.get('dashboard/contractspublic/' + this.studio._id)
                    if (contracts.status === 200 && contracts.data) {
                        contracts = contracts.data
                        if (contracts.length === 1) {
                            let contractId = contracts[0]._id
                            let contract = await this.$axios.get('dashboard/contracts/' + contractId)
                            if (contract.status === 200 && contract.data) {
                                this.prepareContract(contract.data)
                            } else {
                                throw Error('Fehler Laden des Vertrags')
                            }
                        }
                    } else {
                        throw Error('Fehler Laden der Verträge')
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            async prepareContract(contract) {
                let memberships = contract.formFields.find((e) => e.inputType === 'membership')
                let packages = contract.formFields.find((e) => e.inputType === 'packages')

                let premiumContract = memberships.inputValue.find((e) => e.title === 'PREMIUM')
                if (!premiumContract) premiumContract = memberships.inputValue.find((e) => e.title === 'PREMIUM plus')
                let premiumPrice =
                    premiumContract?.studioSettings?.[this.studio._id].durations?.find(
                        (e) => parseInt(e.years) === 1 && parseInt(e.days) === 0 && parseInt(e.months) === 0
                    ).price || null

                if (premiumContract && premiumPrice) {
                    this.contracts.push({
                        title: premiumContract.title,
                        price: premiumPrice,
                    })
                }

                // Use the generic function for other memberships
                const kombiCardContract = this.getMembershipContract(memberships, packages, 'Kombi Card')
                if (kombiCardContract) {
                    this.contracts.push(kombiCardContract)
                }

                const groupFitnessContract = this.getMembershipContract(memberships, packages, 'Group Fitness')
                if (groupFitnessContract) {
                    this.contracts.push(groupFitnessContract)
                }

                const erwachseneContract = this.getMembershipContract(memberships, packages, 'Erwachsene ab 18 Jahren')
                if (erwachseneContract) {
                    this.contracts.push(erwachseneContract)
                }

                const ahvContract = this.getMembershipContract(memberships, packages, 'AHV/Studenten/Lernende')
                if (ahvContract) {
                    this.contracts.push(ahvContract)
                }
            },

            getMembershipContract(memberships, packages, membershipTitle) {
                let membershipContract = memberships.inputValue.find((e) => e.title === membershipTitle)
                let membershipPrice =
                    membershipContract?.studioSettings?.[this.studio._id].durations?.find(
                        (e) => parseInt(e.years) === 1 && parseInt(e.days) === 0 && parseInt(e.months) === 0
                    ).price || null

                let membershipSignUpFee =
                    membershipContract?.studioSettings?.[this.studio._id].durations?.find(
                        (e) => parseInt(e.years) === 1 && parseInt(e.days) === 0 && parseInt(e.months) === 0
                    ).signUpFee || null

                let membershipPackages = []

                if (membershipContract && packages && packages.inputValue) {
                    packages.inputValue.forEach((p) => {
                        let title = p.title

                        let price =
                            p.studioSettings?.[this.studio._id]?.memberships
                                ?.find((e) => e.title === membershipTitle)
                                ?.packageDurations.find(
                                    (e) => parseInt(e.years) === 1 && parseInt(e.days) === 0 && parseInt(e.months) === 0
                                )?.packagePrice || null

                        if (price) membershipPackages.push({ title, price })
                    })
                }

                if (membershipContract && membershipPrice) {
                    return {
                        title: membershipTitle,
                        price: membershipPrice,
                        packages: membershipPackages || [],
                        signUpFee: membershipSignUpFee || null,
                    }
                } else {
                    return null
                }
            },
        },
    }
</script>
<style>
    .ab-class {
        vertical-align: top;
        line-height: 32px;
        margin-right: 5px;
    }
    .price-class {
        font-size: 3rem;
        font-weight: bold;
    }
    .time-class {
    }
    .remove-hover .v-expansion-panel-title__overlay {
        opacity: 0 !important;
    }
</style>
