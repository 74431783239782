<template>
    <div>
        <div v-if="$route.name !== 'clubSearchView'" class="loading-overlay">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </div>
        <router-view></router-view>
        <v-container v-if="$route.name === 'clubSearchView'" style="margin-top: 80px; padding-bottom: 50px">
            <v-row no-gutters>
                <v-col cols="12" class="d-flex align-center">
                    <v-text-field
                        class="custom-autocomplete"
                        v-model="search"
                        autocomplete="nope"
                        append-inner-icon="mdi-magnify"
                        placeholder="Suche nach Kanton, Stadt oder PLZ"
                        variant="outlined"
                        hide-details
                    ></v-text-field>
                    <v-btn @click="showMap = !showMap" variant="text" icon="mdi-google-maps"></v-btn>
                </v-col>

                <v-col cols="12" class="mt-5">
                    <v-card-title class="font-weight-bold text-start pl-0">
                        Finde einen Club in deiner Nähe
                    </v-card-title>
                </v-col>
            </v-row>

            <v-row v-if="showMap">
                <v-col cols="12">
                    <ClubMap />
                </v-col>
            </v-row>

            <!-- Skeleton Loader anzeigen, solange die Daten geladen werden -->
            <v-row v-if="loadingStudios && !loadingStudiosError">
                <v-col v-for="n in 5" :key="n" cols="12" md="6" lg="4">
                    <v-card class="rounded-lg pa-5 club-card" variant="outlined">
                        <v-skeleton-loader height="40px" type="heading"></v-skeleton-loader>
                        <v-skeleton-loader height="50px" type="list-item"></v-skeleton-loader>
                        <v-divider></v-divider>

                        <v-skeleton-loader width="100%" type="actions"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="!loadingStudiosError && studios.length > 0">
                <v-col v-for="studio of filteredStudios" :key="studio._id" cols="12" md="6" lg="4">
                    <v-card class="rounded-lg pa-5 club-card" variant="outlined">
                        <v-card-title class="text-start font-weight-bold text-h5 pt-0">{{ studio.name }}</v-card-title>
                        <v-card-subtitle class="mb-3 text-start">
                            <v-icon>mdi-map-marker</v-icon>
                            {{ studio.adresse }}
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-card-actions class="mt-2 pb-0">
                            <v-btn @click="$router.push('/clubs/' + studio.code)">Club Details</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="openLinkNewWindow('https://app.wellcomefit.ch/contract?studio=' + studio.code)"
                                variant="outlined"
                                color="primary"
                            >
                                Mitglied werden
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="!loadingStudios && filteredStudios.length === 0">
                <v-col cols="12">
                    <v-alert type="info">Keine Studios mit diesen Suchkriterien gefunden</v-alert>
                </v-col>
            </v-row>
            <v-row v-if="loadingStudiosError">
                <v-col cols="12">
                    <v-alert type="error">Fehler beim Laden der Studios.</v-alert>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import ClubMap from '@/components/ClubMap.vue'
    export default {
        beforeRouteEnter(to, from, next) {
            document.title = 'Finde Deinen Fitnessclub – well come FIT group'
            const metaDescription = document.querySelector('meta[name="description"]')
            if (metaDescription) {
                metaDescription.setAttribute(
                    'content',
                    'Auf der Suche nach einem Fitnessclub in deiner Nähe? Finde alle well come FIT Standorte in der Schweiz – dein Weg zu Fitness und Wellness.'
                )
            }
            next()
        },
        components: { ClubMap },
        data() {
            return {
                search: '',
                showMap: false,
            }
        },
        computed: {
            ...mapState({
                studios: (state) => state.studios,
                loadingStudios: (state) => state.loadingStudios,
                loadingStudiosError: (state) => state.loadingStudiosError,
            }),
            filteredStudios() {
                if (!this.search) return this.studios
                const searchLower = this.search.toLowerCase()
                return this.studios.filter((studio) => {
                    return (
                        studio.canton.toLowerCase().includes(searchLower) ||
                        studio.art.toLowerCase().includes(searchLower) ||
                        studio.name.toLowerCase().includes(searchLower) ||
                        studio.adresse.toLowerCase().includes(searchLower) ||
                        studio.email.toLowerCase().includes(searchLower) ||
                        studio.code.toLowerCase().includes(searchLower)
                    )
                })
            },
        },
        async created() {
            await this.fetchStudios()
            const { xs, sm, md, lg, xl, width } = this.$vuetify.display
            if (width > 767) this.showMap = true
        },

        methods: {
            ...mapActions(['fetchStudios']),

            openLinkNewWindow(url) {
                window.open(url, '_blank')
            },
        },
    }
</script>

<style scoped>
    .loading-overlay {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.9); /* Hintergrund mit leichter Transparenz */
        z-index: 9999; /* Damit die Animation über allem angezeigt wird */
    }
    .club-card {
        border-color: grey;
    }
    .v-skeleton-loader__actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .v-skeleton-loader__button {
        padding: 0px 50px;
    }

    /* Autofill styles reset */
    ::v-deep .custom-autocomplete input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
        transition: background-color 5000s ease-in-out 0s;
    }
    ::v-deep .custom-autocomplete input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
        transition: background-color 5000s ease-in-out 0s;
    }
    ::v-deep .custom-autocomplete .v-input__control {
        min-height: 0px;
    }
</style>
