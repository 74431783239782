<template>
    <v-container style="max-width: 700px">
        <!-- Leistungen während der Eventwoche -->
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">LEISTUNGEN WÄHREND DER EVENTWOCHE</h3>
                <v-list dense>
                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🏨</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>
                                    7 Nächte im Standard Doppelzimmer (weitere Zimmerkategorien gegen Aufpreis buchbar)
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🚍</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>Transfer Hotel / Flughafen</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🍽️</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>Ultra All inclusive Verpflegung</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🏋️‍♂️</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>Tägliche Groupfitness Kurse</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>💪</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>
                                    Kostenlose Nutzung des Fitness Studios während der Veranstaltungswoche
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🎉</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>well come Party und Gala Abend</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🧑‍🤝‍🧑</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>
                                    Betreuung durch well come FIT Mitarbeiter vor Ort
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <!-- Deine Vorteile -->
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">DEINE VORTEILE</h3>
                <v-list dense>
                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>📋</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>
                                    Individuelle Trainings- und Ernährungsberatung
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🏋️‍♂️</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>Groupfitness Kurse</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🏋️</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>Fitness Studios</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🤝</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>
                                    Trainer- und Instruktorennetzwerk des well come FIT und winti FIT
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                        <v-row>
                            <v-col cols="1">
                                <v-list-item-icon>🌍</v-list-item-icon>
                            </v-col>
                            <v-col class="text-left">
                                <v-list-item-content>well come FIT und winti FIT Community vor Ort</v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'BenefitsList',
    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold;
    }
</style>
