<template>
    <div class="rounded-lg" style="overflow: hidden">
        <GoogleMap
            api-key="AIzaSyBLmFIshrfQB3kO0bxiqxIc1Voq0vHULvo"
            style="width: 100%; height: 300px"
            :center="center"
            mapId="c3855ce30eeb924a"
            :zoom="8"
        >
            <AdvancedMarker
                @click="showStudio(marker)"
                v-for="marker of markers"
                :options="marker"
                :pin-options="pinOptions"
            />
        </GoogleMap>
        <v-dialog persistent :model-value="selectedStudio !== null">
            <v-card v-if="selectedStudio" class="mx-auto" max-width="300px">
                <v-card-title style="background-color: #00963f" class="text-white font-weight-bold">
                    {{ selectedStudio.name }}
                </v-card-title>
                <Location class="mt-2" :hideTitle="true" :studio="selectedStudio" />
                <v-card-actions>
                    <v-btn @click="selectedStudio = null" variant="text">schliessen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push('/clubs/' + selectedStudio.code)" variant="elevated" color="primary">
                        zum Studio
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { GoogleMap, AdvancedMarker } from 'vue3-google-map'
    import Location from '@/components/club/Location.vue'

    export default {
        components: { GoogleMap, AdvancedMarker, Location },
        data() {
            return {
                center: { lat: 40.689247, lng: -74.044502 },
                markers: [],
                pinOptions: { background: '#00963f', glyphColor: '#FFFFFF', borderColor: '#313131' },
                selectedStudio: null,
            }
        },
        async created() {
            await this.fetchStudios()

            this.markers = this.studios.map((e) => {
                if (!e.location) return { position: { lat: 47.54789, lng: 8.89278 } }

                return { position: { lat: e.location.lat, lng: e.location.long }, title: e.name }
            })

            this.center = {
                lat: Math.min(...this.markers.map((e) => e.position.lat)) + 0.4,

                lng: Math.max(...this.markers.map((e) => e.position.lng)) - 0.9,
            }
        },
        computed: {
            ...mapState({
                studios: (state) => state.studios,
                loadingStudios: (state) => state.loadingStudios,
                loadingStudiosError: (state) => state.loadingStudiosError,
            }),
        },
        methods: {
            ...mapActions(['fetchStudios']),
            showStudio(marker) {
                let studio = this.studios.find((e) => e.name === marker.title)
                if (studio) {
                    this.selectedStudio = studio
                }
            },
        },
    }
</script>

<style lang="scss">
    .gmnoprint,
    .gm-control-active {
        display: none;
    }
</style>
