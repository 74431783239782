import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView.vue'
import AboutView from '../views/AboutView.vue'
import ClubSearchView from '../views/ClubSearchView'
import ClubPageView from '../views/ClubPageView'
import JobView from '../views/JobView.vue'

import Sportwoche from '../views/Sportwoche.vue'

import LeadView from '../views/leads/LeadView.vue'
import LeadDetailView from '../views/leads/LeadDetailView.vue'

import AGBView from '../views/page/AGBView.vue'
import ImpressumView from '../views/page/ImpressumView.vue'
import PolicyView from '../views/page/PolicyView.vue'
import ThankYou from '../views/ThankYou.vue'

const isMobile = () => {
    // Prüfe, ob die Bildschirmbreite kleiner als 768px ist (typisch für mobile Geräte)
    return window.matchMedia('(max-width: 768px)').matches
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/sportwoche',
        name: 'alibey',
        component: Sportwoche,
        meta: { transparentNavbar: isMobile(), hideNavbarLogo: isMobile(), showAlibeyNavbarButton: true },
    },
    {
        path: '/clubs',
        name: 'clubSearchView',
        component: ClubSearchView,
        children: [
            {
                path: ':id',
                name: 'clubPageView',
                component: ClubPageView,
            },
        ],
    },
    {
        path: '/danke',
        name: 'thankyou',
        component: ThankYou,
    },
    {
        path: '/l',
        name: 'leadView',
        component: LeadView,
        children: [
            {
                path: ':link',
                name: 'leadDetailView',
                component: LeadDetailView,
            },
        ],
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: ContactView,
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: JobView,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumView,
    },
    {
        path: '/agb',
        name: 'agb',
        component: AGBView,
    },
    {
        path: '/datenschutz',
        name: 'policy',
        component: PolicyView,
    },
    // ad landing pages

    {
        path: '/google',
        name: 'leadViewGoogle',
        component: LeadDetailView,
    },
    {
        path: '/meta',
        name: 'leadViewMeta',
        component: LeadDetailView,
    },

    // redirects
    {
        path: '/11-tage',
        redirect: '/?gratis-training=true',
    },
    {
        path: '/zehn',
        redirect: '/?gratis-training=true',
    },
    {
        path: '/stellenangebote',
        redirect: '/jobs',
    },
    {
        path: '/:catchAll(.*)',
        redirect: (to) => {
            if (to.path.includes('insta')) {
                return '/meta' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }
            if (to.path.includes('google')) {
                return '/google' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }
            if (to.path.includes('facebook') || to.path.includes('fb')) {
                return '/meta' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }
            if (to.path.includes('website')) {
                return '/?gratis-training=true' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('arbon')) {
                return '/clubs/arbon' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('basel')) {
                return '/clubs/basel' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('buelach')) {
                return '/clubs/buelach' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('dietlikon')) {
                return '/clubs/dietlikon' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('frauenfeld')) {
                return '/clubs/frauenfeld' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('herisau')) {
                return '/clubs/herisau' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('netstal')) {
                return '/clubs/netstal' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('niederurnen')) {
                return '/clubs/niederurnen' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('obfelden')) {
                return '/clubs/obfelden' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('oerlikon')) {
                return '/clubs/oerlikon' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('rikon')) {
                return '/clubs/rikon' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('schwanden')) {
                return '/clubs/schwanden' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('sirnach')) {
                return '/clubs/sirnach' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('einstein') && to.path.includes('gallen')) {
                return '/clubs/stgalleneinstein' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('ost') && to.path.includes('gallen')) {
                return '/clubs/stgallenost' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('west') && to.path.includes('gallen')) {
                return '/clubs/stgallenwest' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('steckborn')) {
                return '/clubs/steckborn' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('wallisellen')) {
                return '/clubs/wallisellen' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('wetzikon')) {
                return '/clubs/wetzikon' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('winterthur')) {
                return '/clubs/winterthur' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            if (to.path.includes('pfungen')) {
                return '/clubs/pfungen' // Weiterleitung zu /insta, wenn "insta" in der URL vorkommt
            }

            return '/' // Standard-Redirect, wenn keine Bedingung zutrifft
        },
    },

    // general redirect
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        redirect: '/',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Wenn savedPosition existiert (z.B. bei Vor-/Zurück-Navigation), nutze diese
        if (savedPosition) {
            return savedPosition
        } else {
            // Scrolle immer zur obersten Position
            return { top: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
    const publicPages = ['impressum', 'datenschutz', 'agb'] // Route-Namen der Seiten, die nicht indexiert werden sollen

    if (publicPages.includes(to.name)) {
        // Wenn die Seite in der Liste der publicPages ist, setze noindex, nofollow
        document.title = `${to.name.charAt(0).toUpperCase() + to.name.slice(1)} - well come FIT group`
        const metaRobots = document.querySelector('meta[name="robots"]')
        if (metaRobots) {
            metaRobots.setAttribute('content', 'noindex, nofollow')
        } else {
            const meta = document.createElement('meta')
            meta.name = 'robots'
            meta.content = 'noindex, nofollow'
            document.head.appendChild(meta)
        }

        const googlebot = document.querySelector('meta[name="googlebot"]')
        if (googlebot) {
            googlebot.setAttribute('content', 'noindex, nofollow')
        } else {
            const meta = document.createElement('meta')
            meta.name = 'googlebot'
            meta.content = 'noindex, nofollow'
            document.head.appendChild(meta)
        }
    } else {
        // Für alle anderen Seiten stelle sicher, dass der Meta-Tag auf index, follow gesetzt ist oder entfernt wird
        const metaRobots = document.querySelector('meta[name="robots"]')
        if (metaRobots) {
            metaRobots.setAttribute('content', 'index, follow')
        }
        const googlebot = document.querySelector('meta[name="googlebot"]')
        if (googlebot) {
            googlebot.setAttribute('content', 'index, follow')
        }
    }

    next()
})

export default router
