<template>
    <v-container class="text-center rounded-lg" style="background-color: #00963f">
        <v-row no-gutters class="justify-center">
            <v-col cols="12">
                <v-card-subtitle opacity="1" class="custom-white-text mb-2 font-weight-bold">
                    Finde Deinen Club
                </v-card-subtitle>
            </v-col>
            <v-col cols="12">
                <v-autocomplete
                    v-model="selectedStudio"
                    autocomplete="nope"
                    :items="filteredStudios"
                    item-title="name"
                    item-value="code"
                    append-inner-icon="mdi-magnify"
                    placeholder="Suche nach Kanton, Stadt oder PLZ"
                    outlined
                    class="custom-autocomplete"
                    hide-details
                    style="background-color: white; border-radius: 4px; flex-grow: 1"
                    no-filter
                    @input="onInput"
                >
                    <template v-slot:item="{ item, index }">
                        <v-list-item
                            @click="selectedStudio = item.value"
                            :class="{ 'border-bottom-except-last': index + 1 !== filteredStudios.length }"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ getStudioInfosByCode(item.value).name }}
                                </v-list-item-title>

                                <v-list-item-subtitle class="mb-1">
                                    {{ getStudioInfosByCode(item.value).adresse.split(',')[0] }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mb-1">
                                    {{ getStudioInfosByCode(item.value).adresse.split(',')[1] }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
                selectedStudio: null,
                search: '',
                filteredStudios: [],
                inputTimeout: null,
            }
        },
        computed: {
            ...mapState({
                studios: (state) => state.studios,
                loadingStudios: (state) => state.loadingStudios,
                loadingStudiosError: (state) => state.loadingStudiosError,
            }),
        },
        watch: {
            search() {
                this.filterStudios()
                this.selectedStudio = null
            },
            selectedStudio() {
                console.log('SELECTED')
                if (this.selectedStudio) this.$router.push('/clubs/' + this.selectedStudio)
            },
        },
        async created() {
            await this.fetchStudios()
            this.filterStudios()
        },
        methods: {
            ...mapActions(['fetchStudios']),
            getStudioInfosByCode(code) {
                let studioInfos = {
                    name: '',
                    adresse: '',
                }
                let studio = this.studios.find((e) => e.code === code)
                if (!studio) return studioInfos
                return { name: studio.name, adresse: studio.adresse }
            },
            filterStudios() {
                if (!this.search) {
                    this.filteredStudios = this.studios
                    return
                }
                const searchLower = this.search.toLowerCase()
                this.filteredStudios = this.studios.filter((studio) => {
                    return (
                        studio.canton.toLowerCase().includes(searchLower) ||
                        studio.art.toLowerCase().includes(searchLower) ||
                        studio.name.toLowerCase().includes(searchLower) ||
                        studio.adresse.toLowerCase().includes(searchLower) ||
                        studio.email.toLowerCase().includes(searchLower) ||
                        studio.code.toLowerCase().includes(searchLower)
                    )
                })
            },
            onInput(val) {
                clearTimeout(this.inputTimeout)
                this.inputTimeout = setTimeout(() => {
                    this.search = val.target.value
                }, 300)
            },
        },
    }
</script>

<style scoped>
    ::v-deep .mdi-menu-down {
        display: none;
    }

    .v-container {
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 90%;
        width: 600px;
        position: relative;
    }
    .custom-white-text {
        color: white !important;
    }

    /* Autofill styles reset */
    ::v-deep .custom-autocomplete input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
        transition: background-color 5000s ease-in-out 0s;
    }
    ::v-deep .custom-autocomplete input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #000 !important;
        transition: background-color 5000s ease-in-out 0s;
    }
    ::v-deep .custom-autocomplete .v-input__control {
        min-height: 0px;
    }

    .border-bottom-except-last {
        border-bottom: 1px solid lightgrey;
    }
</style>
