<template>
    <v-container style="margin-top: 64px" class="mb-5 d-flex align-center justify-center">
        <v-card style="" class="pb-4 mt-5" flat>
            <v-row>
                <v-col cols="12">
                    <h2>Vielen Dank für deine Nachricht!</h2>
                    <p>
                        Wir werden uns schnellstmöglich bei dir melden, um einen Termin fürs Probetraining zu
                        vereinbaren.
                    </p>
                    <v-divider class="my-5"></v-divider>
                    <div>
                        <p>
                            <strong>Vorname:</strong>
                            {{ name }}
                        </p>
                        <p>
                            <strong>Nachname:</strong>
                            {{ surname }}
                        </p>
                        <p>
                            <strong>Email:</strong>
                            {{ email }}
                        </p>
                        <p>
                            <strong>Telefonnummer:</strong>
                            {{ phone }}
                        </p>
                        <p>
                            <strong>Studio:</strong>
                            {{ studio }}
                        </p>
                        <p>
                            <strong>Nachricht:</strong>
                            {{ message }}
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                name: this.$route.query.name || 'N/A',
                surname: this.$route.query.surname || 'N/A',
                email: this.$route.query.email || 'N/A',
                phone: this.$route.query.phone || 'N/A',
                message: this.$route.query.message || 'Keine Nachricht angegeben',
                studio: this.$route.query.studio || 'Nicht ausgewählt',
            }
        },
    }
</script>
